import { PlatformColor } from "../data/common"
import { MatchedListing } from "../data/rest/useGetListings"
import { TbBed, TbChevronLeft, TbChevronRight, TbClock } from "react-icons/tb"
import { useState } from "react"
import moment from "moment"

function hasImage(m: MatchedListing): boolean {
    return m.listing.images && m.listing.images.length > 0
}

function imagesLen(m: MatchedListing): number {
    if (hasImage(m)) {
        return m.listing.images && m.listing.images.length
    }
    return 0
}

function ImageTouchSlider({ listing }: { listing: MatchedListing }) {
    const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0)
    const nImages = imagesLen(listing)

    const cycle = (n: number) => {
        if ((selectedImageIndex + n) < 0) {
            setSelectedImageIndex(nImages - 1)
        } else if ((selectedImageIndex + n) === nImages) {
            setSelectedImageIndex(0)
        } else {
            setSelectedImageIndex(selectedImageIndex + n)
        }
    }

    return (
        <>
            {
                hasImage(listing) ?
                    <div className="relative min-w-fit">
                        <img
                            src={listing.listing.images[selectedImageIndex]}
                            alt={""}
                            className={`h-[320px] w-[568px] object-cover hover:opacity-90`}
                        />
                        <button
                            onClick={() => { cycle(-1) }}
                        >
                            <TbChevronLeft
                                size={30}
                                color="#3E3E42"
                                style={{ transform: "translate(0,-50%)" }}
                                className="absolute left-2 top-1/2 bg-gray-200 bg-opacity-30 px-1 rounded hover:bg-opacity-70"
                            />
                        </button>
                        <button
                            onClick={() => { cycle(1) }}
                        >
                            <TbChevronRight
                                size={30}
                                className="absolute right-2 top-1/2 px-1 rounded bg-opacity-30 bg-gray-200 hover:bg-opacity-70"
                                style={{ transform: "translate(0,-50%)" }}
                                color="#3E3E42"
                            />
                        </button>
                        <div
                            className="absolute bottom-8 left-1/2 flex flex-row space-x-1"
                            style={{ transform: "translate(-50%,0)" }}
                        >
                            {
                                Array.from(Array(nImages).keys()).map((i, ix) =>
                                    <button
                                        key={i}
                                        onClick={() => setSelectedImageIndex(i)}
                                        className={`w-1.5 h-1.5 bg-white shadow border rounded-full opacity-${selectedImageIndex === i ? 100 : 60}`}
                                    >
                                    </button>)
                            }
                        </div>
                    </div>
                    :

                    <div
                        className="h-[300px] w-full bg-gray-200 rounded object-cover hover:opacity-90 items-center flex justify-center"
                    >
                        <p className="font-bold text-gray-500">No Image</p>
                    </div>
            }
        </>
    )
}

function capitaliseFirst(s: string) {
    if (s.length > 0)
        return s[0].toUpperCase() + s.slice(1)
    return s
}

function PlatformMenu({ listing }: { listing: MatchedListing }) {
    return (
        <a
            target={"_blank"}
            rel="noreferrer"
            className="flex flex-row items-center space-x-1 border border-1 border-gray-500 px-2 rounded-xl"
            href={listing.listing.url}
        >
            <svg height="10" width="10">
                <circle cx="5" cy="5" r="5" fill={PlatformColor.get(listing.listing.platform) || "#cccccc"} />
            </svg>
            <p
                className="font-semibold text-sm text-gray-500"
            >
                {capitaliseFirst(listing.listing.platform)}
            </p>
        </a>
    )
}



interface MatchedListingSlotProps {
    listing: MatchedListing
}

export default function MatchedListingSlot(p: MatchedListingSlotProps) {
    return (
        <div className="w-full flex flex-col space-y-3 md:flex-row md:space-x-4 p-2">
            {/* <div className="flex w-full md:w-1/2 items-center"> */}
            <div className="flex min-w-fit items-center">
                <ImageTouchSlider listing={p.listing} />
            </div>
            <div className="flex flex-col flex-1 space-y-2">
                <div className="flex flex-row items-center">
                    <PlatformMenu listing={p.listing} />
                    {/* add badges */}
                </div>
                {/* main header */}
                <div className="flex flex-row items-center justify-between">
                    <p className="flex flex-row items-center space-x-1 font-semibold text-lg text-gray-500">
                        <span>{p.listing.listing.bedrooms}</span><span> <TbBed size={25} /></span>
                    </p>
                    <p className="font-bold text-xl text-gray-800">£{p.listing.listing.price_per_month} pcm</p>
                </div>
                {/* location */}
                <div className="flex flex-row">
                    <p className="text-gray-700 font-bold ">{p.listing.listing.address}</p>
                </div>
                {/* tag badges */}
                {
                    p.listing.account_listing.average_travel_time &&
                    <div className="flex flex-row items-center space-x-1">
                        <TbClock color="gray" size={25} />
                        <p className="font-semibold text-base text-gray-500">
                            <span>
                                {`${p.listing.account_listing.average_travel_time} mins average commute`}
                            </span>
                        </p>
                        <p></p>
                    </div>
                }
                {
                    p.listing.listing.district && p.listing.listing.area && <div className="flex flex-col space-y-0.5">
                        <p className="font-bold text-sm py-0.5 rounded-xl text-gray-500 ">
                            Area: {p.listing.listing.area}
                        </p>
                        <p className="font-bold text-sm py-0.5 rounded-xl text-gray-500 ">
                            Borough: {p.listing.listing.district}
                        </p>
                        {/* <p>{p.listing.account_listing.average_travel_time} mins</p> */}
                    </div>
                }
                <div className="flex flex-row items-center justify-between">
                    {/* badges */}
                    <div className="flex flex-row flex-wrap space-x-2">
                        {
                            [].map((item) =>
                                (<span key={item} className="text-xs font-bold text-gray-500 bg-gray-200 px-2 py-1 rounded">{item}</span>))
                        }
                    </div>
                    <p className="text-gray-500 text-sm font-semibold">{moment(p.listing.listing.date_listed).fromNow()}</p>
                </div>
            </div>
        </div>
    )
}
