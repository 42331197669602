import { ReactElement, createContext, useContext, useState } from "react";
import localstorage from "../components/lib/localstorage";
import { ROLE_ADMIN, USER_ACCOUNT_KEY } from "../constants";

export interface LoggedInUser {
  auth_token: string;
  account_id: string;
  email: string;
  expiry: number;
  email_alerts_enabled?: boolean;
  role?: string;
}

export interface UserCtxProp {
  getUser(): LoggedInUser | null;
  setUser(user: LoggedInUser | null): void;
  isLoggedIn(): boolean;
  isAdmin(): boolean;
}

export function getStoredUser(): LoggedInUser | null {
  return localstorage.get<LoggedInUser>(USER_ACCOUNT_KEY);
}

export function storeUser(user: LoggedInUser | null) {
  localstorage.set<LoggedInUser>(USER_ACCOUNT_KEY, user);
}

export function UserManager(
  currentUser: LoggedInUser | null,
  setCurrentUser: (u: LoggedInUser | null) => void
): UserCtxProp {
  return {
    getUser(): LoggedInUser | null {
      const storedUser = getStoredUser();
      // if stored user is empty but current user is not
      if (!storeUser && currentUser) {
        setCurrentUser(storedUser);
      }
      return currentUser;
    },
    setUser(user: LoggedInUser | null): void {
      storeUser(user);
      setCurrentUser(user ? user : null);
    },
    isLoggedIn(): boolean {
      if (currentUser) {
        const storedUser = getStoredUser();
        if (!storeUser) setCurrentUser(storedUser);
      }

      if (!currentUser) return false;
      const expired = currentUser.expiry < Date.now() / 1000;
      return (
        currentUser.account_id.length > 0 &&
        currentUser.auth_token.length > 0 &&
        !expired
      );
    },
    isAdmin(): boolean {
      if (!currentUser) return false;
      return currentUser.role === ROLE_ADMIN;
    },
  };
}

const defaultUser = UserManager(null, () => {});

const UserContext = createContext<UserCtxProp>(defaultUser);

export const useUser = () => useContext(UserContext);

interface UserProviderProps {
  children: ReactElement | ReactElement[];
}

export const UserProvider = (props: UserProviderProps) => {
  const [user, setUser] = useState<LoggedInUser | null>(
    localstorage.get(USER_ACCOUNT_KEY)
  );
  return (
    <UserContext.Provider value={UserManager(user, setUser)}>
      {props.children}
    </UserContext.Provider>
  );
};
