import logger from "../../components/lib/logger";
import httprequest from "../../components/lib/httprequest";
import { REAL_SHIFT_HOST } from "../../constants";

interface UpdateAccountResponse {
    email: string
    account_id: string
    email_alerts_enabled: boolean
}

export async function updateAccount(authToken: string, enableEmailAlerts?: boolean): Promise<UpdateAccountResponse> {
    // try {
    logger.debug("updating account")
    //     let uri = `${REAL_SHIFT_HOST}/update_account?Authorization=${authToken}`
    //     if (enableEmailAlerts !== undefined) {
    //         uri += "&enable_email_alerts=" + (enableEmailAlerts ? "true" : "false")
    //     }

    //     logger.debug("uri is", uri)
    //     const { data } = await axios.get<UpdateAccountResponse>(uri)
    //     return data
    // } catch (e: any) {
    //     const axErr = (e as AxiosError)
    //     if (axErr.response) {
    //         if (axErr.response.status === 401) throw ErrLogout
    //     }
    //     throw e
    // }
    let url = `${REAL_SHIFT_HOST}/update_account?Authorization=${authToken}`
    if (enableEmailAlerts !== undefined) {
        url += "&enable_email_alerts=" + (enableEmailAlerts ? "true" : "false")
    }
    try {
        const result = await httprequest.get<UpdateAccountResponse>(url)
        return result
    } catch (err) {
        logger.error({ url, err })
        throw err
    }
}

export async function useGetAccount(authToken: string, account: UpdateAccountResponse) {
}