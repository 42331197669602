import { useEffect, useRef, useState } from "react";
import isEmail from "validator/lib/isEmail";
import { LoggedInUser, useUser } from "../context/UserContext";
import { onRequestCode, onVerifyCode } from "../data/rest/useGetCode";
import logger from "./lib/logger";
import { getParam } from "./lib/params";

interface LoginFormProp {
  onLoginSuccess(): void;
}

export default function LoginForm(prop: LoginFormProp) {
  const { setUser } = useUser();

  const codeRef = useRef(null);

  const emailParam = getParam("email");
  const codeParam = getParam("code");
  const [email, setEmail] = useState<string>("");
  const [isGettingCode, setIsGettingCode] = useState<boolean>(false);

  const [code, setCode] = useState<string>("");
  const [isVerifyingCode, setIsVerifyingCode] = useState<boolean>(false);

  const verifyCode = (email: string, code: string) => {
    setIsVerifyingCode(true);
    onVerifyCode(email, code)
      .then((resp) => {
        const user: LoggedInUser = {
          auth_token: resp.auth_token,
          account_id: resp.account_id,
          email_alerts_enabled: resp.email_alerts_enabled,
          email,
          expiry: resp.expiry,
          role: resp.role,
        };
        setUser(user);
        prop.onLoginSuccess();
      })
      .catch(console.error)
      .finally(() => setIsVerifyingCode(false));
  };

  const onGetCode = () => {
    if (email.length === 0 && !isEmail(email)) throw new Error("invalid email");
    setIsGettingCode(true);
    onRequestCode(email)
      .then((data) => {
        logger.debug("/requestcode", data);
        // codeRef.current.focus()
        if (codeRef.current) {
          (codeRef.current as any).focus();
        }
      })
      .catch(console.error)
      .finally(() => setIsGettingCode(false));
  };

  useEffect(() => {
    if (emailParam && codeParam) {
      console.log("setting params");
      setEmail(emailParam);
      setCode(codeParam);
    }
  }, [emailParam, codeParam]);

  return (
    <div className="px-6 py-3 rounded border">
      <div className="flex flex-col items-center justify-center mb-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-12 h-12"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
          />
        </svg>
        <h2 className="text-2xl font-bold">Login / Signup</h2>
      </div>
      <form
        action="#"
        onSubmit={(e) => {
          e.preventDefault();
          logger.debug(code, email);
          if (email.length > 0 && code.length === 0) {
            logger.debug("getting code");
            onGetCode();
            return;
          } else if (email.length > 0 && code.length > 0) {
            logger.debug("verifying code");
            verifyCode(email, code);
            return;
          }
          // verifyCode(email, code)
        }}
        className="flex flex-col space-y-4"
      >
        <div className="flex flex-col">
          <label className="text-xs text-gray-400">Email</label>
          <div className="flex">
            <input
              className={`flex-grow rounded-l p-2 pl-2 border-t mr-0 border-b border-l text-gray-800 bg-white text-sm ${
                isGettingCode && "disabled"
              } ${isEmail(email) ? "border-gray-200" : " border-red-500"} `}
              onChange={(e) =>
                e.target.value.length && setEmail(e.target.value)
              }
              placeholder="your@mail.com"
              type="email"
              defaultValue={emailParam || undefined}
              required
            />
            <button
              onClick={(e) => {
                e.preventDefault();
                onGetCode();
              }}
              type="button"
              className="flex px-3 rounded-r bg-green-300  py-2 border-green-300 border-t border-b border-r text-sm"
            >
              {isGettingCode && (
                <svg
                  className="animate-spin -ml-1 mr-1 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              )}
              <p className="text-white font-semibold ">Get Code</p>
            </button>
          </div>
        </div>
        <div className="flex flex-col">
          <label className="text-xs text-gray-400">Code</label>
          <input
            ref={codeRef}
            className="border rounded px-3 py-2 mt-2"
            type={"text"}
            placeholder="12345"
            onChange={(e) => e.target.value.length && setCode(e.target.value)}
            defaultValue={codeParam || undefined}
          />
        </div>
        <div className="flex flex-col items-center justify-center my-3">
          <button className="flex flex-row items-center space-x-2 justify-center my-3 py-1 w-full rounded bg-green-300 text-white">
            {isVerifyingCode && (
              <svg
                className="animate-spin -ml-1 mr-1 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            )}
            <p className="font-semibold">Submit</p>
          </button>
        </div>
      </form>
    </div>
  );
}
