import { ReactElement } from 'react'
import { Mail, Home, PenTool } from 'react-feather'
import { Link } from 'react-router-dom'

interface CenterPageProps {
    children?: ReactElement | ReactElement[]
}

export function CenterPage(props: CenterPageProps) {
    return (
        <div className="flex items-center min-h-screen">
            <div className="container mx-auto">
                <div className="flex flex-col items-center space-y-5 justify-center">
                    {
                        props.children
                    }
                </div>
            </div>
        </div>

    )
}

export function PopularPages() {
    return (
        <div className="flex flex-col space-y-4 divide-y text-left">
            <p className="text-gray-600 font-semibold text-xs">POPULAR PAGES</p>
            <Link to={"/"}>
                <div className="flex flex-row items-center space-x-3">
                    <div className="p-3">
                        <Home size={30} />
                    </div>
                    <div className="flex flex-col text-left">
                        <p className="font-semibold text-base">Home</p>
                        <p className="text-xs">Subscribe via email by going to our home page</p>
                    </div>
                </div>
            </Link>

            <Link to={"/contact"}>
                <div className="flex flex-row items-center space-x-3">
                    <div className="p-3">
                        <Mail size={30} />
                    </div>
                    <div className="flex flex-col text-left">
                        <p className="font-semibold text-base">Contact</p>
                        <p className="text-xs">If you want to get in touch, do not heisitate to contact us</p>
                    </div>
                </div>
            </Link>
            <Link to={"/about"}>
                <div className="flex flex-row items-center space-x-3">
                    <div className="p-3">
                        <PenTool size={30} />
                    </div>
                    <div className="flex flex-col text-left">
                        <p className="font-semibold text-base">About</p>
                        <p className="text-xs">Learn more about us.</p>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default function NotFound() {
    return (
        <div className="flex items-center min-h-screen">
            <div className="container mx-auto">
                <div className="flex flex-col items-center space-y-5 justify-center">
                    <div className="flex flex-col space-y-1">
                        <p
                            className="text-base font-bold text-upper"
                            style={{
                                "color": "#6a93cb",
                            }}
                        >404 ERROR</p>
                        <p className="text-3xl font-semibold">This Page does not exist</p>
                        <p className="text-base text-gray-500">The page you are looking for could not be found.</p>
                    </div>
                    <PopularPages />
                </div>
            </div>
        </div>
    )
}