import { useEffect, useRef } from "react";

interface ModalProps {
  children: any;
  show: boolean
  onOutsideClick?(): void
  handleClose(): void
}

export default function ModalContainer(props: ModalProps) {
  const ref = useRef(null)

  const keyHandler = (e: any) => {
    if (e.key !== "Escape") {
      return;
    }
    props.handleClose();
  };

  // close the modal if the esc key is pressed
  useEffect(() => {
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });


  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !(ref.current as any).contains(event.target)) {
        props.onOutsideClick && props.onOutsideClick()
      }
    }
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [props.onOutsideClick]) // eslint-disable-line 

  return (
    <>
      {props.show ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div
              className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div
                ref={ref}
                className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {props.children}
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
