import axios from "axios";
import { ReactElement, useEffect, useRef, useState } from "react";
import { BiMinus, BiPlus } from "react-icons/bi";
import { CgOptions } from "react-icons/cg";
import { HiOutlineCurrencyPound } from "react-icons/hi";
import {
  MdCheckCircleOutline,
  MdOutlineBedroomChild,
  MdOutlineCancel,
} from "react-icons/md";
import { TbLocation, TbMinus } from "react-icons/tb";
import Dropdown from "../components/Dropdown";
import logger from "../components/lib/logger";
import Toggle from "../components/Toggle";
import { REAL_SHIFT_HOST } from "../constants";
import { BinaryOption, LocationInfo, LocationType } from "../data/common";
import { SearchCriteria } from "../data/rest/useGetCriteria";
import PostcodeDropdown from "../components/PostcodeDropdown";

const priceList = [
  100, 250, 500, 1000, 1250, 1500, 2000, 2500, 3000, 4000, 5000, 7500, 10000,
  12500, 15000, 20000, 25000, 50000,
];

const bedsList = [
  ...Array(10)
    .fill(0)
    .map((_, x) => x + 1),
];

interface LocationSearchDropdownProps {
  defaultIncludeList: LocationInfo[];
  defaultExcludeList: LocationInfo[];
  inputClassAdditions?: string;
  containerClassAdditions?: string;
  onIncludeListChange(vals: LocationInfo[]): void;
  onExcludeListChange(vals: LocationInfo[]): void;
  isActive?(b: boolean): void;
}

export const defaultLocationSuggestions: LocationInfo[] = [
  { name: "Bromley", loc_type: "district" },
  { name: "Croydon", loc_type: "district" },
  { name: "Kingston upon Thames", loc_type: "district" },
  { name: "Hackney", loc_type: "district" },
  { name: "Harrow", loc_type: "district" },
  { name: "Kensington and Chelsea", loc_type: "district" },
];

function LocationSearchDropdown(props: LocationSearchDropdownProps) {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [suggestions, setSuggestions] = useState<LocationInfo[]>(
    defaultLocationSuggestions
  );
  const [includeList, setIncludeList] = useState<LocationInfo[]>([]);
  const [excludeList, setExcludeList] = useState<LocationInfo[]>([]);
  const [nameSet, setNameSet] = useState<Set<string>>(new Set());

  const searchRef = useRef(null);
  const focusSearch = () =>
    setTimeout(() => {
      (searchRef.current as any).focus();
    }, 200);

  const setIsActive = (b: boolean) => props.isActive && props.isActive(b);
  const inputClassAdditions = props.inputClassAdditions || "";

  const inputChange = (e: any) => {
    if (!dropdownOpen) {
      setDropdownOpen(true);
      setIsActive(true);
    }
    const val = e.target.value;
    setSearchText(val);
    if (val.length > 0) {
      axios
        .get<LocationInfo[]>(`${REAL_SHIFT_HOST}/suggest_district?q=${val}`)
        .then(({ data }) => {
          const filtered = data
            .filter((elem) => !!elem)
            .filter((elem) => !nameSet.has(elem.name));
          if (filtered.length > 0) setSuggestions(filtered);
        })
        .catch(console.error);
    }
  };

  const onSelect = (loc: LocationInfo, fromIncludeList: boolean) => {
    if (fromIncludeList) {
      if (includeList.indexOf(loc) === -1) {
        const newSelection = [...includeList, loc];
        setIncludeList(newSelection);
        if (props.onIncludeListChange !== undefined) {
          props.onIncludeListChange(newSelection);
        }
      }
    } else {
      if (excludeList.indexOf(loc) === -1) {
        const newSelection = [...excludeList, loc];
        setExcludeList(newSelection);
        if (props.onExcludeListChange !== undefined) {
          props.onExcludeListChange(newSelection);
        }
      }
    }

    const newSet = new Set<string>(nameSet);
    newSet.add(loc.name);
    setNameSet(newSet);

    const sugg = [...suggestions];
    setSuggestions(sugg.filter((e) => !newSet.has(e.name)));
  };

  const onDeselect = (loc: LocationInfo, fromIncludeList: boolean) => {
    if (fromIncludeList) {
      const newSelection = includeList.filter((e) => e.name !== loc.name);
      setIncludeList(newSelection);
      if (props.onIncludeListChange !== undefined) {
        props.onIncludeListChange(newSelection);
      }
    } else {
      const newSelection = excludeList.filter((e) => e.name !== loc.name);
      setExcludeList(newSelection);
      if (props.onExcludeListChange !== undefined) {
        props.onExcludeListChange(newSelection);
      }
    }

    const newSet = new Set<string>(nameSet);
    newSet.delete(loc.name);
    setNameSet(newSet);

    const sugg = [...suggestions];
    setSuggestions(sugg.filter((e) => !newSet.has(e.name)));
  };

  useEffect(() => {
    if (props.defaultIncludeList.length)
      setIncludeList(props.defaultIncludeList);
    if (props.defaultExcludeList.length)
      setExcludeList(props.defaultExcludeList);
  }, [props.defaultIncludeList, props.defaultExcludeList]);

  return (
    <div className={`${props.containerClassAdditions || ""}`}>
      <Dropdown
        outsideClicked={() => {
          setDropdownOpen(false);
        }}
        isOpen={() => {}}
        defaultIsOpen={dropdownOpen}
        buttonOverride={true}
        elem={
          <div className="">
            <div className="flex flex-row items-center bg-lime-50 py-1 rounded-2xl ">
              <span className="px-2">
                <TbLocation size={22} />
              </span>
              <input
                ref={searchRef}
                onClick={(e) => {
                  e.stopPropagation();
                  setDropdownOpen(true);
                  setIsActive(true);
                }}
                onChange={inputChange}
                type="text"
                value={searchText}
                className={`font-semibold outline-none py-1 rounded-r-2xl px-2 bg-lime-50 text-black ${inputClassAdditions}`}
                placeholder="(Optional) Search locations"
              />
            </div>
            {(includeList.length > 0 || excludeList.length > 0) && (
              <div className="flex flex-col space-y-2 mt-2 px-2">
                {includeList.length > 0 && (
                  <div className="flex flex-row items-center space-x-2">
                    <span className="font-bold text-sm my-1">
                      <MdCheckCircleOutline size={20} color={"76af50"} />
                    </span>
                    {/* <div className="flex flex-row flex-wrap space-x-2"> */}
                    <div className="flex flex-row flex-wrap">
                      {includeList.map((val) => (
                        <button
                          key={val.name}
                          onClick={() => onDeselect(val, true)}
                          className="px-2 py-0.5  m-1 text-sm font-semibold rounded bg-lime-200"
                        >
                          {val.name}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
                {excludeList.length > 0 && (
                  <div className="flex flex-row space-x-2 ">
                    <span className="font-bold text-sm my-1">
                      <MdOutlineCancel size={20} color={"fecaca"} />
                    </span>

                    <div className="flex flex-row flex-wrap">
                      {excludeList.map((val) => (
                        <button
                          key={val.name}
                          onClick={() => onDeselect(val, false)}
                          className="px-2 py-0.5 m-1 text-sm font-semibold rounded bg-red-200"
                        >
                          {val.name}
                        </button>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        }
      >
        {dropdownOpen ? (
          suggestions.map((elem, ix) => (
            <div
              key={elem.name}
              className="text-body-color text-left hover:bg-lime-400 hover:text-primary block text-sm font-semibold hover:bg-opacity-5"
            >
              <div className="w-96 flex flex-row px-3 items-center cursor-pointer">
                <span
                  className="grow"
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelect(elem, true);

                    setSearchText("");
                    focusSearch();
                    // TODO checking to see if this

                    setDropdownOpen(false);
                    setIsActive(false);
                  }}
                >
                  {elem.name}
                </span>
                <div className="flex flex-row w-fit">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      onSelect(elem, true);

                      setSearchText("");
                      focusSearch();
                    }}
                    className="rounded bg-lime-200 py-1 px-2 m-2"
                  >
                    <span>
                      <BiPlus size={20} />
                    </span>
                  </button>
                  <button
                    className="rounded bg-red-200 py-1 px-2 m-2"
                    onClick={(e) => {
                      logger.debug("adding to exclude list");
                      e.stopPropagation();
                      onSelect(elem, false);

                      setSearchText("");
                      focusSearch();

                      // setDropdownOpen(false)
                      // setIsActive(false)
                    }}
                  >
                    <span>
                      <BiMinus size={20} />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </Dropdown>
    </div>
  );
}

interface TextDropdownProps {
  data: any[];
  default: any;
  prefix?: ReactElement;
  suffix?: ReactElement;
  ddTextAlign?: string;
  elemWidthOverride?: number;
  ddWidthOverride?: number;
  ddAlign?: string;
  isActive?(active: boolean): void;
  onSelect(ix: number): void;
}

function TextDropdown(props: TextDropdownProps) {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [current, setCurrent] = useState<any>(props.default);
  const widthOverride = props.elemWidthOverride
    ? `w-${props.elemWidthOverride}`
    : "";
  const ddWidthOverride = props.ddWidthOverride
    ? `w-${props.ddWidthOverride}`
    : "";
  const ddTextAlign = props.ddTextAlign || "text-center";

  useEffect(() => {
    if (props.default) setCurrent(props.default);
  }, [props.default]);

  return (
    <Dropdown
      defaultIsOpen={dropdownOpen}
      outsideClicked={() => {
        if (dropdownOpen) {
          props.isActive && props.isActive(!dropdownOpen);
        }
      }}
      isOpen={(isOpen) => {
        setDropdownOpen(isOpen);
        props.isActive && props.isActive(isOpen);
      }}
      ddAlign={props.ddAlign}
      elem={
        <>
          {props.prefix && props.prefix}
          <span className={`${props.prefix ? "ml-1" : ""} ${widthOverride}`}>
            {current}
          </span>
          {props.suffix && props.suffix}
        </>
      }
    >
      {props.data.map((elem, ix) => (
        <button
          key={elem}
          className={`text-body-color ${ddTextAlign} ${ddWidthOverride} hover:bg-primary hover:text-primary block py-2 text-base font-semibold hover:bg-opacity-5 w-full`}
          onClick={() => {
            const value = props.data[ix];
            setCurrent(value);
            props.onSelect(ix);
            setDropdownOpen(!dropdownOpen);
            props.isActive && props.isActive(false);
          }}
        >
          {elem}
        </button>
      ))}
    </Dropdown>
  );
}

interface CommuteData {
  location: string;
  duration: number;
}

interface SearchBarProps {
  defaultParam?: SearchCriteria;
  isActive?(active: boolean): void;
  onChange?(criteria: SearchCriteria): void;
}

export default function Searchbar(props: SearchBarProps) {
  const [minPriceIndex, setMinPriceIndex] = useState<number>(0);
  const [maxPriceIndex, setMaxPriceIndex] = useState<number>(minPriceIndex + 1);
  const [minBedIndex, setMinBedIndex] = useState<number>(0);
  const [maxBedIndex, setMaxBedIndex] = useState<number>(0);
  const [includeLocations, setIncludeLocations] = useState<LocationInfo[]>([]);
  const [excludeLocations, setExcludeLocations] = useState<LocationInfo[]>([]);
  const [commute, setCommute] = useState<CommuteData | undefined>(undefined);
  const [nearSupermarkets, setNearSupermarkets] = useState<number>(
    BinaryOption.Yes
  );
  const [first, setFirst] = useState<boolean>(true);
  const [optionsOpen, setOptionsOpen] = useState<boolean>(false);

  const handleChange = () => {
    logger.debug(includeLocations);
    const payload: SearchCriteria = {
      _id: "",
      near_supermarkets: nearSupermarkets,
      included_areas: includeLocations
        .filter((e) => e.loc_type === LocationType.Area)
        .map((e) => e.name),
      included_districts: includeLocations
        .filter((e) => e.loc_type === LocationType.District)
        .map((e) => e.name),
      excluded_areas: excludeLocations
        .filter((e) => e.loc_type === LocationType.Area)
        .map((e) => e.name),
      excluded_districts: excludeLocations
        .filter((e) => e.loc_type === LocationType.District)
        .map((e) => e.name),
      price_max: priceList[maxPriceIndex],
      price_min: priceList[minPriceIndex],
      beds_min: bedsList[minBedIndex],
      beds_max: bedsList[maxBedIndex],
      work_postcode: commute?.location || "",
      travel_time: commute?.duration || 0,
    };
    props.onChange && props.onChange(payload);
  };

  useEffect(() => {
    if (props.defaultParam) {
      const params = props.defaultParam!;
      const includeLocs: LocationInfo[] = [];
      if (params.included_areas) {
        includeLocs.push(
          ...params.included_areas!.map((e) => ({
            name: e,
            loc_type: LocationType.Area,
          }))
        );
      }
      if (params.included_districts) {
        includeLocs.push(
          ...params.included_districts!.map((e) => ({
            name: e,
            loc_type: LocationType.District,
          }))
        );
      }

      const excludeLocs: LocationInfo[] = [];
      if (params.excluded_areas) {
        excludeLocs.push(
          ...params.excluded_areas!.map((e) => ({
            name: e,
            loc_type: LocationType.Area,
          }))
        );
      }
      if (params.excluded_districts) {
        excludeLocs.push(
          ...params.excluded_districts!.map((e) => ({
            name: e,
            loc_type: LocationType.District,
          }))
        );
      }

      setIncludeLocations(includeLocs);
      setExcludeLocations(excludeLocs);
      setMinBedIndex(bedsList.findIndex((e) => e === params.beds_min));
      setMinBedIndex(bedsList.findIndex((e) => e === params.beds_max));
      setMinPriceIndex(bedsList.findIndex((e) => e === params.price_min));
      setMinPriceIndex(bedsList.findIndex((e) => e === params.price_max));
      setNearSupermarkets(params.near_supermarkets);
    }
    setFirst(false);
  }, [props.defaultParam]);

  useEffect(() => {
    if (!first) handleChange();
  }, [
    maxBedIndex,
    minBedIndex,
    minPriceIndex,
    maxPriceIndex,
    includeLocations,
    excludeLocations,
    nearSupermarkets,
    // commute,
  ]);

  return (
    <div className="bg-white flex justify-center items-start flex-col space-y-2 lg:flex-row lg:space-y-0 lg:space-x-4 w-fit border-2 border-lime-800 border-opacity-30 shadow-md rounded-3xl px-4 py-4">
      <div className="flex flex-col lg:grow-1 justify-center lg:pr-2 lg:border-r-2 ">
        <LocationSearchDropdown
          onIncludeListChange={(vals) => {
            logger.debug("on list include change", vals);
            setIncludeLocations(vals);
            // handleChange()
          }}
          onExcludeListChange={(vals) => {
            setExcludeLocations(vals);
            // handleChange()
          }}
          defaultIncludeList={includeLocations}
          defaultExcludeList={excludeLocations}
          inputClassAdditions="w-[500px] lg:w-[600px]"
          containerClassAdditions="w-[550px] lg:w-[650px]"
          isActive={props.isActive}
        />
      </div>
      <div className="flex flex-col w-full lg:w-fit">
        <div className="bg-white flex justify-center items-start flex-col space-y-2 lg:flex-row lg:space-y-0 lg:space-x-4 w-full">
          <div className="py-2 flex justify-center w-full lg:w-fit bg-lime-50">
            <div className="flex flex-row items-center space-x-2  bg-lime-50 rounded-xl">
              <HiOutlineCurrencyPound fontSize={24} />
              <TextDropdown
                data={priceList}
                elemWidthOverride={14}
                ddWidthOverride={16}
                ddTextAlign={"text-left"}
                onSelect={(ix) => {
                  setMinPriceIndex(ix);
                  if (maxPriceIndex < ix) {
                    setMaxPriceIndex(Math.min(ix + 1, priceList.length - 1));
                  }
                  // handleChange()
                }}
                isActive={props.isActive}
                default={priceList[minPriceIndex]}
              />
              <span>
                <TbMinus />
              </span>
              <TextDropdown
                onSelect={(ix) => {
                  setMaxPriceIndex(ix);
                  if (ix < minPriceIndex) {
                    setMinPriceIndex(Math.max(ix - 1, 0));
                  }
                  // handleChange()
                }}
                isActive={props.isActive}
                elemWidthOverride={14}
                ddWidthOverride={16}
                ddTextAlign={"text-right"}
                ddAlign={"right-0"}
                data={priceList}
                default={priceList[maxPriceIndex]}
              />
            </div>
          </div>
          <div className="py-2 flex justify-center w-full lg:w-fit bg-lime-50">
            <div className="flex flex-row space-x-2 items-center rounded-xl">
              <MdOutlineBedroomChild size={24} />
              <TextDropdown
                data={bedsList}
                elemWidthOverride={4}
                ddWidthOverride={8}
                ddTextAlign={"text-left"}
                onSelect={(ix) => {
                  setMinBedIndex(ix);
                  if (ix > maxBedIndex) {
                    setMaxBedIndex(ix);
                  }

                  // handleChange()
                }}
                isActive={props.isActive}
                default={bedsList[minBedIndex]}
              />
              <span>
                <TbMinus />
              </span>
              <TextDropdown
                onSelect={(ix) => {
                  setMaxBedIndex(ix);
                  if (minBedIndex > ix) {
                    setMinBedIndex(ix);
                  }
                  // handleChange()
                }}
                isActive={props.isActive}
                elemWidthOverride={6}
                ddWidthOverride={8}
                ddTextAlign={"text-right"}
                ddAlign={"right-0"}
                data={bedsList}
                default={bedsList[maxBedIndex]}
              />
            </div>
          </div>
          <div className="py-2 flex justify-center w-full lg:w-fit">
            <div className="flex flex-row items-center space-x-1 ">
              <div
                className={`border-black border-2 rounded-full p-0.5 ${
                  optionsOpen ? "bg-lime-100" : ""
                }`}
                onClick={() => {
                  setOptionsOpen(!optionsOpen);
                }}
              >
                <CgOptions size={20} />
              </div>
            </div>
          </div>
        </div>
        {optionsOpen && (
          <div className="p-4 flex flex-col divide-y-2 space-y-2">
            <div className="flex flex-row items-center space-x-2">
              <span className="text-sm font-semibold">Commute</span>
              <PostcodeDropdown
                isActive={props.isActive}
                placeholder="Enter a postcode (e.g. SE1 9SF)"
                onChange={(opt) => {
                  if (opt.commuteTime > 0 && opt.postcode.length > 0) {
                    setCommute({
                      location: opt.postcode,
                      duration: opt.commuteTime,
                    });
                  }
                }}
              />
            </div>
            <div className="flex flex-row items-center justify-between py-2">
              <label className="text-sm font-semibold">
                Include Supermarkets
              </label>
              <Toggle
                defaultValue={
                  nearSupermarkets === BinaryOption.Yes ? true : false
                }
                onClassOverride="bg-lime-300"
                onChange={(change) => {
                  const nearSupermarkets = change
                    ? BinaryOption.Yes
                    : BinaryOption.No;
                  setNearSupermarkets(nearSupermarkets);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
