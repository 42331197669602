import axios from "axios";
import { useEffect, useState } from "react";
import { REAL_SHIFT_HOST } from "../constants";
import { LocationInfo } from "../data/common";

interface Props {
    hint?: string
    placeholder: string
    defaultValues: LocationInfo[]
    defaultSuggestions: LocationInfo[]
    onChange(val: LocationInfo[]): void
}

export default function LocationTagSearch({
    placeholder,
    defaultSuggestions,
    defaultValues,
    onChange,
}: Props) {

    const [searchText, setSearchText] = useState<string>("")
    const [suggestions, setSuggestions] = useState<LocationInfo[]>(defaultSuggestions)
    // const [selected, setSelected] = useState<LocationInfo[]>(defaultValues)
    const [selected, setSelected] = useState<Map<string, LocationInfo>>(
        defaultValues.reduce((acc, loc) => {
            acc.set(loc.name, loc)
            return acc
        }, new Map())
    )

    const inputChange = (e: any) => {
        const val = e.target.value
        setSearchText(val)
        axios.get<LocationInfo[]>(`${REAL_SHIFT_HOST}/suggest_district?q=${val}`)
            .then(({ data }) => {
                setSuggestions(data.filter(d => !!d))
            }).catch(console.error)
    }


    const onSelect = (loc: LocationInfo) => {
        // if (selected.indexOf(loc) === -1) {
        if (!selected.has(loc.name)) {
            const values = Array.from(selected.values())
            const newSelection = [...values, loc]
            setSelected(map => new Map(selected.set(loc.name, loc)))
            if (onChange !== undefined) {
                onChange(newSelection)
            }
        }
    }

    const onDeselect = (loc: LocationInfo) => {
        const newSelection = new Map(selected)
        newSelection.delete(loc.name)
        setSelected(newSelection)
        if (onChange !== undefined) {
            onChange(Array.from(newSelection.values()))
        }
    }

    useEffect(() => {
        if (defaultValues.length > 0) {
            // setSelected([...defaultValues])
            const newSelection = defaultValues.reduce((acc, loc) => {
                return acc.set(loc.name, loc)
            }, new Map<string, LocationInfo>())
            setSelected(newSelection)
        }
    }, [defaultValues])

    return (
        <div>
            <div className="flex flex-col space-y-2">
                <div className="flex items-stretch w-full relative h-15 bg-white rounded">
                    <input
                        type="text"
                        placeholder={placeholder}
                        onChange={inputChange}
                        value={searchText}
                        className="flex-shrink flex-grow border shadow-sm leading-normal w-px flex-1 h-10 border-grey-light rounded rounded-l-none px-3 self-center relative text-l outline-none text-sm"
                    />
                </div>
                <div className=' flex flex-wrap'>
                    {suggestions.filter((suggestion) => !selected.has(suggestion.name))
                        .slice(0, 5)
                        .map((s) => (
                            <span key={s.name}
                                id={s.name}
                                onClick={() => onSelect(s)}
                                className="m-1 bg-gray-200 hover:bg-gray-300 rounded-full px-2 font-semibold text-xs leading-loose cursor-pointer" >
                                {s.name}
                            </span>
                        ))}
                </div>
                {selected.size > 0 && (<p className="text-sm">Your selections: (click to remove)</p>)}
                <div className='flex flex-row flex-wrap'>
                    {Array.from(selected.values()).map((s) => (
                        <span
                            key={s.name}
                            id={s.name}
                            onClick={() => onDeselect(s)}
                            className="m-1 bg-green-200 hover:bg-green-300 rounded-full px-2 font-semibold text-xs leading-loose cursor-pointer" >
                            {s.name}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    )
}
