import { useState } from "react"

interface QuestionInputProp {
  validator?(v: any): boolean
  onchange(v: any): void
  onButtonClick(): void
  type: string
  pretext?: string
  placeholder: string
  hint?: string
  buttonText: string
}


export default function EmailRequestInput(props: QuestionInputProp) {
  const [valid, setValid] = useState<boolean>(true)

  const valueUpdated = (v: any) => {
    let nowValid = valid
    if (props.validator) {
      nowValid = props.validator(v)
      setValid(nowValid)
    }

    if (nowValid) {
      if (props.type === "number") {
        return props.onchange(parseInt(v, 10))
      }
      props.onchange(v)
    }
  }

  return (
    <div className="bg-gray-50 px-4 py-3 sm:px-6">
      <div className="sm:fle">
        <div className="flex flex-wrap items-stretch w-full relative h-15 bg-white rounded mb-3">
          {props.pretext && (
            <div className="flex -mr-px justify-center w-15 p-1">
              <span
                className="flex items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-l text-gray-600"
              >
                {props.pretext}
              </span>
            </div>
          )}

          <div className="flex flex-row items-center justify-between max-w-md mx-auto rounded-lg w-full">
            <input
              type={props.type}
              placeholder={props.placeholder}
              onChange={(evt) => valueUpdated(evt.target.value)}
              className={`grow leading-normal rounded rounded-r-none border-0 h-10  ${valid ? "border-1 border-grey-light" : "border-y-2 border-l-2 border-r-0 border-red-300"} px-3 self-center relative  font-roboto text-l outline-none`}
            />
            <button
              onClick={props.onButtonClick}
              className="bg-green-500 px-3 py-2 rounded rounded-l-none font-semibold text-white "><p>{props.buttonText}</p></button>
          </div>
        </div>
      </div>
      {!valid && <p className="text-red-300 text-sm font-semibold">{props.hint}</p>}
    </div >
  )
}
