import { ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../context/UserContext";

interface LoginRedirectProps {
  children: ReactElement | ReactElement[];
}

export default function LoginRedirect({ children }: LoginRedirectProps) {
  const { isLoggedIn, getUser } = useUser();
  const loggedIn = isLoggedIn();
  return loggedIn && getUser() ? <>{children}</> : <Navigate to={"/login"} />;
}
