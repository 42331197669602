import axios from "axios";
import { useEffect, useState } from "react";
import { REAL_SHIFT_HOST } from "../constants";
import logger from "./lib/logger";
import Dropdown from "./Dropdown";

interface Props {
  hint?: string;
  placeholder: string;
  defaultValue?: CommuteOption;
  validator?(v: any): boolean;
  onChange(opt: CommuteOption): void;
  isActive?(b: boolean): void;
}

export interface CommuteOption {
  postcode: string;
  commuteTime: number;
}

export default function PostcodeDropdown({
  placeholder,
  defaultValue,
  onChange,
  validator,
  isActive,
}: Props) {
  const [searchText, setSearchText] = useState<string>("");
  const [validPostcode, setValidPostcode] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [commuteTime, setCommuteTime] = useState<number>(0);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const inputChange = (e: any) => {
    if (!isDropdownOpen) {
      setIsDropdownOpen(true);
      isActive && isActive(true);
    }

    logger.debug("changing stuff", suggestions, isDropdownOpen);

    const val = e.target.value;
    setSearchText(val);

    if (val.length === 0) {
      onChange({ postcode: "", commuteTime });
      return;
    }
    axios
      .get<string[]>(
        `${REAL_SHIFT_HOST}/suggest_postcode?q=${val.toUpperCase()}`
      )
      .then(({ data }) => {
        setSuggestions(data);
      })
      .catch(console.error);

    let nowValidPostcode = validPostcode;
    if (validator) {
      nowValidPostcode = validator(val);
      setValidPostcode(val);
    }

    if (nowValidPostcode) {
      logger.debug("updating postcode " + val);
      onChange({ postcode: val, commuteTime: commuteTime });
    }
  };

  const onSelectPostcode = (name: string) => {
    setSearchText(name);
    if (onChange !== undefined) {
      onChange({ postcode: name, commuteTime: commuteTime });
    }
    setTimeout(() => {
      setSuggestions([]);
    }, 100);
  };

  const onSelectDuration = (value: number) => {
    logger.debug("changed duration to ", value);
    setCommuteTime(value);
    if (onChange) {
      onChange({ postcode: searchText, commuteTime: value });
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setSearchText(defaultValue.postcode);
      setCommuteTime(defaultValue.commuteTime);
    }
  }, [defaultValue]);

  return (
    <div className="w-full">
      <Dropdown
        defaultIsOpen={isDropdownOpen}
        outsideClicked={() => {
          setIsDropdownOpen(false);
        }}
        isOpen={(open) => {
          setIsDropdownOpen(open);
        }}
        elem={
          <div className="flex flex-row space-x-2 items-center justify-between">
            <input
              type="text"
              className={`font-semibold text-gray-600 px-2 h-10 w-full md:w-60 bg-lime-50 outline-none rounded-l text-sm flex-1 ${
                searchText.length > 0 && "uppercase"
              }`}
              onChange={inputChange}
              value={searchText}
              onClick={(e) => {
                e.stopPropagation();
                setIsDropdownOpen(true);
                isActive && isActive(true);
              }}
              placeholder="Work postcode e.g (WC1A 2EE)"
            />
            <div className="pr-2 h-10 flex flex-row items-center text-sm bg-white rounded-r">
              <select
                value={commuteTime}
                onChange={(e) => {
                  const val = parseInt(e.target.value, 10);
                  onSelectDuration(val);
                }}
                onClick={() => {
                  logger.debug("selected clicked");
                  setIsDropdownOpen(true);
                  isActive && isActive(true);
                }}
              >
                {Array.from(Array(20).keys()).map((i) => (
                  <option key={i} value={i * 5}>
                    {`${i * 5}`}
                  </option>
                ))}
              </select>
              <p>mins</p>
            </div>
          </div>
        }
      >
        {isDropdownOpen ? (
          <div className="flex flex-col items-start divide-y w-full">
            {suggestions.map((suggestion) => (
              <button
                className="hover:bg-lime-200 px-2 py-1"
                onClick={() => {
                  onSelectPostcode(suggestion);
                  setIsDropdownOpen(false);
                }}
                key={suggestion}
              >
                {suggestion}
              </button>
            ))}
          </div>
        ) : (
          <></>
        )}
      </Dropdown>
    </div>
  );
}
