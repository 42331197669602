import axios from "axios";
import { useEffect, useState } from "react";
import { REAL_SHIFT_HOST } from "../constants";
import { defaultLocationSuggestions, LocationInfo } from "../data/common";
import Card from "./Card";
import QuestionText from "./QuestionText";

interface Props {
  question: string;
  hint?: string;
  placeholder: string;
  defaultValues: LocationInfo[];
  onChange(val: LocationInfo[]): void;
}

export default function TagSearch({
  question,
  placeholder,
  defaultValues,
  hint,
  onChange,
}: Props) {
  const [searchText, setSearchText] = useState<string>("");
  const [suggestions, setSuggestions] = useState<LocationInfo[]>(
    defaultLocationSuggestions
  );
  const [selected, setSelected] = useState<LocationInfo[]>(defaultValues);

  const inputChange = (e: any) => {
    const val = e.target.value;
    setSearchText(val);
    axios
      .get<LocationInfo[]>(`${REAL_SHIFT_HOST}/suggest_district?q=${val}`)
      .then(({ data }) => {
        setSuggestions(data.filter((elem) => !!elem));
      })
      .catch(console.error);
  };

  const onSelect = (loc: LocationInfo) => {
    if (selected.indexOf(loc) === -1) {
      const newSelection = [...selected, loc];
      setSelected(newSelection);
      if (onChange !== undefined) {
        onChange(newSelection);
      }
    }
  };

  const onDeselect = (loc: LocationInfo) => {
    const newSelection = selected.filter((e) => e.name !== loc.name);
    setSelected(newSelection);
    if (onChange !== undefined) {
      onChange(newSelection);
    }
  };

  useEffect(() => {
    if (defaultValues.length > 0) {
      setSelected([...defaultValues]);
    }
  }, [defaultValues]);

  return (
    <Card>
      <div className="flex flex-col items-center w-[350px] md:w-[450px]">
        <QuestionText text={question} />
        {hint && (
          <p className="text-sm font-semibold px-4 py-3 sm:px-6 ">{hint}</p>
        )}
        <div className="bg-gray-50 px-4 py-3 sm:px-6">
          <div className="sm:flexw-[320px] md:w-[420px]">
            <div className="flex flex-wrap items-stretch w-full relative h-15 bg-white rounded mb-3 pr-10">
              <input
                type="text"
                placeholder={placeholder}
                onChange={inputChange}
                value={searchText}
                className="flex-shrink flex-grow leading-normal w-px flex-1 border-0 h-10 border-grey-light rounded rounded-l-none px-3 self-center relative  font-roboto text-l outline-none"
              />
            </div>
          </div>
          <div className="my-3 flex flex-wrap">
            {suggestions
              .filter((e) => selected.indexOf(e) === -1)
              .slice(0, 5)
              .map((s) => (
                <span
                  key={s.name}
                  id={s.name}
                  onClick={() => onSelect(s)}
                  className="m-1 bg-gray-200 hover:bg-gray-300 rounded-full px-2 font-bold text-sm leading-loose cursor-pointer"
                >
                  {s.name}
                </span>
              ))}
          </div>
          {selected.length > 0 && <p>Your selections: (click to remove)</p>}
          <div className="my-3 flex flex-wrap">
            {selected.map((s) => (
              <span
                key={s.name}
                id={s.name}
                onClick={() => onDeselect(s)}
                className="m-1 bg-green-200 hover:bg-green-300 rounded-full px-2 font-bold text-sm leading-loose cursor-pointer"
              >
                {s.name}
              </span>
            ))}
          </div>
        </div>
      </div>
    </Card>
  );
}
