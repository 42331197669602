import Home from "./pages/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Contact from "./pages/Contact";
import Footer from "./components/Footer";
import { UserProvider } from "./context/UserContext";
import NotFound from "./pages/NotFound";
import { useState } from "react";
import LoginForm from "./components/LoginForm";
import Login from "./pages/Login";
import ModalContainer from "./components/ModalContainer";
import Criteria from "./pages/Criteria";
import Privacy from "./pages/Privacy";
import Setting from "./pages/Setting";
import Search from "./pages/Search";
import logger from "./components/lib/logger";
import Unsubscribe from "./pages/Unsubscribe";
import SearchModal from "./pages/SearchModal";

function App() {
  const [isLoginOpen, setIsLoginOpen] = useState<boolean>(false);

  return (
    <BrowserRouter>
      <UserProvider>
        <div className="container mx-auto h-screen flex flex-col">
          <Navbar
            login={() => {
              setIsLoginOpen(!isLoginOpen);
              logger.debug("login click");
            }}
            logout={() => {
              logger.debug("logout click");
            }}
          />
          <div className="flex-1 flex-col grow">
            <Routes>
              <Route path="*" element={<NotFound />} />
              <Route path="/">
                <Route index element={<Home />} />
                <Route path="criteria/:criteria_id" element={<Criteria />} />
                <Route path="contact" element={<Contact />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="login" element={<Login />} />
                <Route path="unsubscribe" element={<Unsubscribe />} />
                {/* <Route path="dashboard" element={<Dashboard />} /> */}
                <Route path="settings" element={<Setting />} />
                <Route path="search" element={<Search />} />
                <Route path="searchv2" element={<SearchModal />} />
              </Route>
            </Routes>
          </div>
          <Footer />
          <ModalContainer
            handleClose={() => setIsLoginOpen(false)}
            onOutsideClick={() => setIsLoginOpen(false)}
            show={isLoginOpen}
          >
            <LoginForm onLoginSuccess={() => setIsLoginOpen(false)} />
          </ModalContainer>
        </div>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
