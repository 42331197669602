import { useEffect, useState } from "react"

interface ToggleProps {
    defaultValue: boolean
    onChange(b: boolean): void
    onClassOverride?: string
    offClassOverride?: string
}

export default function Toggle(props: ToggleProps) {
    const [value, setValue] = useState<boolean>(props.defaultValue)

    useEffect(() => {
        setValue(props.defaultValue)
    }, [props.defaultValue])

    const onClass = props.onClassOverride || "bg-green-300"
    const offClass = props.offClassOverride || "bg-red-300"

    return (
        <div>
            <div
                className="flex items-center cursor-pointer cm-toggle-wrapper"
                onClick={() => {
                    const newValue = !value
                    setValue(newValue)
                    props.onChange(newValue)
                }}
            >
                <span className="font-semibold text-xs mr-1">
                    Off
                </span>
                <div className={`rounded-full w-8 h-4 p-0.5 ${value ? onClass : offClass}`}>
                    <div
                        className={`rounded-full w-3 h-3 bg-white transform mx-auto duration-300 ease-in-out ${value ? "translate-x-2" : "-translate-x-2"}`}
                    >
                    </div>
                </div>
                <span className="font-semibold text-xs ml-1">
                    On
                </span>
            </div >
        </div >
    )
}