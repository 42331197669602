import { useEffect, useState } from "react";
import Validator from "validator";
import Card from "./Card";
import QuestionText from "./QuestionText";
import TwoThumbSlider from "./TwoThumbSlider";
import EmailRequestInput from "./EmailRequestInput";
import { onRequestCode, onVerifyCode } from "../data/rest/useGetCode";
import { useUser } from "../context/UserContext";
import { getCriteria, SearchCriteria } from "../data/rest/useGetCriteria";
import TagSearch from "./TagSearch";
import { useNavigate } from "react-router-dom";
import { BinaryOption } from "../data/common";
import logger from "./lib/logger";
import {
  TbMap,
  TbBed,
  TbCurrencyPound,
  TbShoppingCartPlus,
} from "react-icons/tb";
import Toggle from "./Toggle";
import QuestionInput from "./QuestionInput";

logger.debug("App environment: ", process.env.NODE_ENV);

interface FormAppProps {
  criteria_id?: string;
}

function FormApp(props: FormAppProps) {
  const { isLoggedIn, getUser, setUser } = useUser();
  const user = getUser();

  const navigate = useNavigate();

  const [postcode, setPostcode] = useState<string>("");
  const [commute, setCommute] = useState<number>(-1);

  const [minPrice, setMinPrice] = useState<number>(5000);
  const [maxPrice, setMaxPrice] = useState<number>(9900);
  const [hasSetPrice, setHasSetPrice] = useState<boolean>(false);

  const [minBeds, setMinBeds] = useState<number>(5);
  const [maxBeds, setMaxBeds] = useState<number>(9);
  const [hasSetBeds, setHasSetBeds] = useState<boolean>(false);

  const [nearSupermarkets, setNearSupermarkets] = useState<number>(
    BinaryOption.No
  );

  const [verificationCode, setVerificationCode] = useState<string>("");

  const [accountID, setAccountID] = useState<string>("");

  const [hasRequestedEmailCode, setHasRequestedEmailCode] =
    useState<boolean>(false);
  const [email, setEmail] = useState<string>("");

  const [includedAreas, setIncludedAreas] = useState<string[]>([]);
  const [excludedAreas, setExcludedAreas] = useState<string[]>([]);

  const [showInvalidCodeError, setShowInvalidCodeError] =
    useState<boolean>(false);

  const [includedDistricts, setIncludedDistricts] = useState<string[]>([]);
  const [excludedDistricts, setExcludedDistricts] = useState<string[]>([]);

  const validMinPrice = (v: number) =>
    maxPrice === -1 || (v > 0 && v <= maxPrice);
  const validMaxPrice = (v: number) => v > minPrice;
  const validCode = (code: string) => code.length === 6;

  const allValid = () =>
    hasSetBeds &&
    hasSetBeds &&
    validMinPrice(minPrice) &&
    validMaxPrice(maxPrice) &&
    ((Validator.isEmail(email) && hasRequestedEmailCode) || isLoggedIn());

  const requestEmailCode = (email: string) => {
    if (!Validator.isEmail(email)) {
      throw new Error("invalid email " + email);
    }
    // setIsRequestingEmailCode(true)
    onRequestCode(email)
      .then(({ success }) => {
        setHasRequestedEmailCode(success);
      })
      .catch(console.error)
      .finally(() => {
        // setIsRequestingEmailCode(false)
      });
  };

  const verifySentCode = (email: string, code: string): Promise<void> => {
    if (!Validator.isEmail(email)) {
      throw new Error("invalid email " + email);
    }
    if (code.length === 0 || code.length !== 6) {
      throw new Error("invalid verification code " + code);
    }
    return new Promise((resolve, reject) => {
      onVerifyCode(email, code)
        .then((resp) => {
          setAccountID(resp.account_id);
          setUser({
            auth_token: resp.auth_token,
            account_id: resp.account_id,
            email: email,
            role: resp.role,
            expiry: resp.expiry,
          });
          resolve();
        })
        .catch((err) => {
          logger.error("error verifying code", err);
          setShowInvalidCodeError(true);
          reject();
        });
    });
  };

  useEffect(() => {
    if (!props.criteria_id) {
      logger.debug("no criteria id provided");
      return;
    }
    if (!user) {
      logger.debug("no user provided");
      return;
    }

    getCriteria(user?.auth_token, props.criteria_id).then((criteria) => {
      setCommute(criteria?.travel_time || commute);
      setMinBeds(criteria?.beds_min || minBeds);
      setMaxBeds(criteria?.beds_max || maxBeds);
      setHasSetBeds(true);

      setMinPrice(criteria?.price_min || minPrice);
      setMaxPrice(criteria?.price_max || maxPrice);
      setHasSetPrice(true);

      setPostcode(criteria?.work_postcode || postcode);
      setIncludedAreas(criteria?.included_areas || []);
      setExcludedAreas(criteria?.excluded_areas || []);

      setIncludedDistricts(criteria?.included_districts || []);
      setExcludedDistricts(criteria?.excluded_districts || []);

      setNearSupermarkets(criteria.near_supermarkets);
    });
    /* tslint:disable */
  }, [props.criteria_id]); // eslint-disable-line

  return (
    <div className="flex flex-col items-center w-full">
      <div className="my-10 flex flex-col items-center">
        <h1 className="text-5xl font-bold text-green-400 font-mons">
          Realshift
        </h1>
        <h2 className="text-2xl text-green-400">zero-fuss flat search.</h2>
      </div>
      <div className="flex flex-col space-y-2 px-4 text-center">
        <p className="text-lg flex flex-row items-center space-x-1 justify-center">
          <span>Let's find you a dream flat in London </span>
          <TbMap />
        </p>
        <p className="text-lg">
          Just answer a few simple questions and we will do the rest.
        </p>
      </div>
      <div className="grid grid-col-1 grid-flow-row gap-y-3">
        {
          <Card>
            <QuestionText text="What's the minimum and maximum price you're willing to pay per month?" />
            <TwoThumbSlider
              min={100}
              max={10000}
              step={100}
              defaultMin={minPrice}
              defaultMax={maxPrice}
              leftTitle={<TbCurrencyPound />}
              rightTitle={<TbCurrencyPound />}
              updateMinValue={(v) => {
                if (!hasSetPrice) setHasSetPrice(true);
                setMinPrice(v);
              }}
              updateMaxValue={(v) => {
                if (!hasSetPrice) setHasSetPrice(true);
                setMaxPrice(v);
              }}
            />
          </Card>
        }

        {hasSetPrice && (
          <Card>
            <QuestionText text="How many bedrooms are you looking for?" />
            <p className="wrap text-center p-2 px-6 font-semibold text-sm">
              NOTE: 0 means studio
            </p>
            <TwoThumbSlider
              min={0}
              max={10}
              step={1}
              defaultMin={minBeds}
              defaultMax={maxBeds}
              leftTitle={<TbBed size={20} />}
              rightTitle={<TbBed size={20} />}
              updateMinValue={(v) => {
                if (!hasSetBeds) setHasSetBeds(true);
                setMinBeds(v);
              }}
              updateMaxValue={(v) => {
                if (!hasSetBeds) setHasSetBeds(true);
                setMaxBeds(v);
              }}
            />
          </Card>
        )}

        {hasSetPrice && hasSetBeds && (
          <Card>
            <div className="flex-col items-center justify-center w-[350px] md:w-[450px]">
              <QuestionText text="Do you want to be close to a supermarket?" />
              <p className="wrap text-center p-2 px-6 font-semibold text-sm">
                NOTE: This will only include rentals with supermarkets within a
                1 mile radius
              </p>
              <div className="flex flex-row items-center justify-center my-2">
                <TbShoppingCartPlus size={20} />
                <div className="py-2 px-4 flex flex-row items-center space-x-5">
                  <Toggle
                    defaultValue={
                      nearSupermarkets === BinaryOption.Yes ? true : false
                    }
                    onChange={(change) => {
                      setNearSupermarkets(
                        change ? BinaryOption.Yes : BinaryOption.No
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </Card>
        )}

        {hasSetPrice && hasSetBeds && (
          <TagSearch
            question="(Optional) What area are you interested in?"
            hint="NOTE: This will only find properties in this area"
            placeholder="Start typing.. e.g. Croydon"
            defaultValues={[
              ...includedAreas.map((v) => ({ name: v, loc_type: "area" })),
              ...includedDistricts.map((v) => ({
                name: v,
                loc_type: "district",
              })),
            ]}
            onChange={(locations) => {
              // setIncludedAreas
              if (locations.length === 0) {
                return;
              }
              console.log("setting included area location", locations);
              const area = [];
              const districts = [];
              for (let i = 0; i < locations.length; i++) {
                if (locations[i].loc_type === "area")
                  area.push(locations[i].name);
                else districts.push(locations[i].name);
              }
              if (area.length > 0) setIncludedAreas(area);
              if (districts.length > 0) setIncludedDistricts(districts);
            }}
          />
        )}

        {hasSetPrice &&
          hasSetBeds &&
          validMinPrice(minPrice) &&
          validMaxPrice(maxPrice) && (
            <TagSearch
              question="(Optional) What areas are you NOT interested in?"
              hint="NOTE: This will exclude properties in this area"
              placeholder="Start typing.. e.g. Croydon"
              defaultValues={[
                ...excludedAreas.map((v) => ({ name: v, loc_type: "area" })),
                ...excludedAreas.map((v) => ({
                  name: v,
                  loc_type: "district",
                })),
              ]}
              onChange={(locations) => {
                if (locations.length === 0) {
                  return;
                }
                logger.debug("setting excluded location", locations);
                const area = [];
                const districts = [];
                for (let i = 0; i < locations.length; i++) {
                  if (locations[i].loc_type === "area") {
                    area.push(locations[i].name);
                  } else {
                    districts.push(locations[i].name);
                  }
                }
                if (area.length > 0) {
                  setExcludedAreas(area);
                }
                if (districts.length > 0) {
                  setExcludedDistricts(districts);
                }
              }}
            />
          )}

        {!isLoggedIn() &&
          hasSetBeds &&
          hasSetPrice &&
          validMinPrice(minPrice) &&
          validMaxPrice(maxPrice) && (
            <Card>
              <QuestionText text="What's your email address?" />
              <EmailRequestInput
                validator={Validator.isEmail}
                // hint="email should be valid"
                type="email"
                placeholder="Enter your email address"
                onchange={setEmail}
                onButtonClick={() => requestEmailCode(email)}
                buttonText="Request Code"
              />
            </Card>
          )}

        {!isLoggedIn() && hasRequestedEmailCode && (
          <Card>
            <div>
              <QuestionText text="Enter verification code?" />
              {showInvalidCodeError && (
                <p className="mx-6 my-1 text-red-300 font-semibold">
                  please enter correct code{" "}
                </p>
              )}
              <QuestionInput
                onchange={(value) => {
                  setVerificationCode(value);
                  setShowInvalidCodeError(false);
                }}
                type="text"
                placeholder="e.g. 12345"
                validator={(value) => value.length === 6}
              />
            </div>
          </Card>
        )}

        {allValid() && (
          <div className="flex flex-col items-center">
            <div className="text-l my-3">
              <div className="text-center">
                <p>Get ready for the easiest flat hunt of your life.</p>
                <p>
                  We will show you flats{" "}
                  <b>
                    {minBeds} - {maxBeds} beds
                  </b>{" "}
                  ranging from{" "}
                  <b>
                    £{minPrice} - £{maxPrice}
                  </b>
                </p>
                <p>
                  You'll receive an email within minutes of each matching
                  property being added to the market
                </p>
                <p> - so you'll be the first to snap up your favorite</p>
                <p className="my-4 font-bold">
                  Just confirm and let us do the rest ☕️
                </p>
              </div>
            </div>
            <button
              className="text-white font-bold bg-green-500 hover:bg-green-600 py-2 px-4 rounded"
              onClick={() => {
                const criteria: SearchCriteria = {
                  _id: "",
                  account_id: accountID,
                  work_postcode: postcode,
                  travel_time: commute,
                  price_min: minPrice,
                  price_max: maxPrice,
                  beds_min: minBeds,
                  beds_max: maxBeds,
                  included_areas: includedAreas, // to support both api
                  excluded_areas: excludedDistricts, // to support both apis
                  included_districts: includedDistricts,
                  excluded_districts: excludedDistricts,
                  near_supermarkets: nearSupermarkets,
                };
                if (isLoggedIn()) {
                  navigate("/search", { state: criteria });
                  return;
                }

                if (!validCode(verificationCode)) {
                  setShowInvalidCodeError(true);
                  return;
                }

                verifySentCode(email, verificationCode).then(() => {
                  logger.debug("navigating to search");
                  navigate("/search", { state: criteria });
                });
              }}
            >
              Search 🚀
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default FormApp;
