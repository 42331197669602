import LoginRedirect from "../components/LoginRedirect";
import Toggle from "../components/Toggle";
import { LoggedInUser, useUser } from "../context/UserContext";
import { updateAccount } from "../data/rest/useGetAccount";

export default function Setting() {
  const { getUser, setUser } = useUser();
  const currentUser = getUser();
  const isEmailAlertEnabled: boolean = currentUser
    ? currentUser.email_alerts_enabled!
    : false;

  const toggleOn = () => {
    const user = getUser();
    if (user) {
      updateAccount(user!.auth_token, true)
        .then((resp) => {
          if (user) {
            const newUser: LoggedInUser = {
              ...user,
              email_alerts_enabled: resp.email_alerts_enabled,
            };
            setUser(newUser);
          }
        })
        .catch(console.error)
        .finally(() => {});
    }
  };

  const toggleOff = () => {
    const user = getUser();
    if (user) {
      updateAccount(user!.auth_token, false)
        .then((resp) => {
          if (user) {
            const newUser: LoggedInUser = {
              ...user,
              email_alerts_enabled: resp.email_alerts_enabled,
            };
            setUser(newUser);
          }
        })
        .catch(console.error)
        .finally(() => {});
    }
  };

  return (
    <LoginRedirect>
      <div className="flex flex-col px-2 justify-center items-center">
        <div className="grid grid-cols-2 gap-y-1 grid-flow-row w-max">
          <div className="flex items-center">
            <p className="font-bold text-sm">Email Address</p>
          </div>
          <div className="">
            <p className="text-sm">{currentUser?.email}</p>
          </div>
          <div className="flex items-center">
            <p className="font-bold text-sm">Account Id</p>
          </div>
          <div className="">
            <p className="text-sm">{currentUser?.account_id}</p>
          </div>
        </div>
      </div>

      <div className="flex w-full flex-col  py-2 items-center ">
        <p className="uppercase text-xs font-semibold border-b w-full text-center py-2">
          notifications
        </p>
        <div className="grid grid-cols-2 grid-flow-row w-max">
          <div className="flex items-center pr-5 border-b">
            <p className="font-bold text-sm">Email Alerts</p>
          </div>
          <div className="border-l py-2 border-b">
            <Toggle
              defaultValue={isEmailAlertEnabled}
              onChange={(value) => (value ? toggleOn() : toggleOff())}
            />
          </div>
        </div>
      </div>
    </LoginRedirect>
  );
}
