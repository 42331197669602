import { useEffect, useState } from "react";
import httprequest from "../components/lib/httprequest";
import logger from "../components/lib/logger";
import { getParam } from "../components/lib/params";
import { REAL_SHIFT_HOST } from "../constants";
import { useUser } from "../context/UserContext";

interface UnsubscribeResponse {
  account_id: string;
  email_alerts_enabled: boolean;
  email: string;
}

export default function Unsubscribe() {
  const email = getParam("email");
  const token = getParam("token");

  const { getUser, setUser } = useUser();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUnsubscribed, setIsUnsubscribed] = useState<boolean>(false);

  useEffect(() => {
    if (!token) {
      logger.debug("no auth token or email", { email, token });
      return;
    }
    setIsLoading(true);
    const uri = `${REAL_SHIFT_HOST}/unsubscribe?email=${email}&token=${token}`;
    httprequest
      .get<UnsubscribeResponse>(uri)
      .then((resp) => {
        logger.debug("successfully unsubscribed");
        setIsUnsubscribed(true);

        const user = getUser();
        if (user) {
          setUser({ ...user, email_alerts_enabled: resp.email_alerts_enabled });
        }
      })
      .catch(logger.error)
      .finally(() => setIsLoading(false));
    /* tslint:disable */
  }, []); // eslint-disable-line

  return (
    <div className="flex justify-center items-center text-center">
      {isLoading ? (
        <div className="flex flex-row space-x-1 items-center">
          <svg
            className="animate-spin -ml-1 mr-1 h-5 w-5 text-black"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <p>Loading...</p>
        </div>
      ) : (
        <p className="text-center text-lg py-10">
          {isUnsubscribed
            ? `Successfully unsubscribed`
            : `An error occurred unsubscribing, please contact us immediately.`}
        </p>
      )}
    </div>
  );
}
