import { useNavigate } from "react-router-dom";
import LoginForm from "../components/LoginForm";


export default function Login() {
    const navigate = useNavigate()
    return (
        <div className="flex items-center justify-center py-3">
            <LoginForm
                onLoginSuccess={() => {
                    navigate("/search")
                }}
            />
        </div>
    )
}