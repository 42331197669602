interface QuestionTextProps {
    text: string
}

export default function QuestionText(props: QuestionTextProps) {
    return (
        <div className="bg-white px-2 pt-5 pb-2 w-[350px] md:w-[450px]">
            <div className="sm:flex sm:items-start">
                <div className="mt-4 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-base text-center leading-6 font-medium text-gray-900" id="modal-headline">
                        {props.text}
                    </h3>
                </div>
            </div>
        </div>
    )
}