import axios from "axios";
import { useState } from "react";
import { useForm } from "react-hook-form"
import { CheckCircle } from "react-feather"
import { REAL_SHIFT_HOST } from "../constants";

interface ContactInformation {
  email_address: string
  phone_number?: string
  message: string
  fullname: string
}


export default function Contact() {
  const { register, reset, handleSubmit } = useForm<ContactInformation>()
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false)

  const onSubmit = async (contactInfo: ContactInformation) => {
    if (contactInfo.fullname.length === 0) {
      return
    } else if (contactInfo.email_address.length === 0) {
      console.error('empty email address')
      return
    } else if (contactInfo.message.length === 0) {
      console.error('empty message')
      return
    }
    try {
      const { status, data } = await axios.post(REAL_SHIFT_HOST + '/contactus', contactInfo)
      if (status >= 200 && status < 400) {
        console.error("finished submitting form")
      }
      console.error("error submitting form", { status, data })
    } catch (e) {
      console.error("error thrown")
    }
    setFormSubmitted(true)
  }

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="">
        {
          formSubmitted ?
            <div className="text-center flex flex-col space-y-1">
              <div className="flex flex-row justify-center items-center space-x-2 ">
                <CheckCircle />
                <h1 className="text-3xl font-semibold text-gray-700">
                  Message Sent
                </h1>
              </div>
              <p className="text-gray-400">We will be in contact soon.</p>
              <div>
                <button
                  className="p-2 border rounded border-blue-200"
                  onClick={() => {
                    reset()
                    setFormSubmitted(false)
                  }}
                >Go back</button>
              </div>
            </div>
            :
            <div className="max-w-md bg-white p-5 rounded-md shadow-sm">
              <div className="text-center">
                <h1 className="my-3 text-3xl font-semibold text-gray-700 ">Contact Us</h1>
                <p className="text-gray-400">Fill up the form below to send us a message.</p>
              </div>
              <div className="m-7">
                <form
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="mb-6">
                    <label htmlFor="name" className="block mb-2 text-sm text-gray-600 ">Full Name</label>
                    <input
                      {...register("fullname")}
                      type="text" name="fullname" id="fullname" placeholder="John Doe" required className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300" />
                  </div>
                  <div className="mb-6">
                    <label htmlFor="email" className="block mb-2 text-sm text-gray-600 ">Email Address</label>
                    <input
                      {...register("email_address")}
                      type="email" name="email_address" id="email_address" placeholder="you@company.com" required className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 " />
                  </div>
                  <div className="mb-6">

                    <label htmlFor="phoneNumber" className="text-sm text-gray-600">Phone Number</label>
                    <input
                      {...register("phone_number")}
                      type="text"
                      name="phone_number" id="phone_number" placeholder="020 6322 4232" className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 " />
                  </div>
                  <div className="mb-6">
                    <label htmlFor="message" className="block mb-2 text-sm text-gray-600">Your Message</label>

                    <textarea
                      {...register("message")}
                      rows={5} name="message" id="message" placeholder="Your Message" className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 " required></textarea>
                  </div>
                  <div className="mb-6">
                    <button
                      type="submit"
                      className="font-bold w-full px-3 py-4 text-white bg-green-300 rounded-md focus:bg-green-600 focus:outline-none">Send Message</button>
                  </div>
                  <p className="text-base text-center text-gray-400" id="result">
                  </p>
                </form>
              </div>
            </div>

        }
      </div>
    </div >
  );
}

