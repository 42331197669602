import { useEffect, useState } from "react"
import { MatchedListing } from "../data/rest/useGetListings"

interface DistrictHeaderProps {
    listings: MatchedListing[]
    defaultValue: string
    onClick(value: string): void
}

export default function DistrictHeaders(props: DistrictHeaderProps) {
    const [selectedItem, setSelectedItem] = useState<string>(props.defaultValue)

    let item: { [key: string]: number } = {}
    const result = Object.entries(props.listings.reduce((acc, cur) => {
        const district = cur.listing.district
        acc[district] = ((acc[district]) || 0) + 1
        return acc
    }, item)).sort((a, b) => b[1] - a[1]).map(([district, count]) => (
        <button
            key={district}
            onClick={() => {
                const selected = district === selectedItem ? "" : district
                setSelectedItem(selected)
                props.onClick(selected)
            }}
            className={`flex mx-3 my-1 py-1 px-2 flex-row space-x-2 ${selectedItem === district ? "bg-green-400" : "bg-green-200"} rounded-lg`}>
            <span className="text-sm">{district}</span>
            <span className="text-sm font-semibold">{count}</span>
        </button>
    ))

    useEffect(() => {
        setSelectedItem(props.defaultValue)
    }, [props.defaultValue])

    return result.length ? (<div className="flex flex-row flex-wrap">{result}</div>) : <></>
}