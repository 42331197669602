import axios, { AxiosError } from "axios"
import { USER_ACCOUNT_KEY } from "../../constants"
import { LoggedInUser } from "../../context/UserContext"
import localstorage from "./localstorage"
import logger from "./logger"

export async function get<T>(uri: string): Promise<T> {
    const user = localstorage.get<LoggedInUser>(USER_ACCOUNT_KEY)
    let url = uri
    try {
        const { data, status } = await axios.get<T>(url, {
            headers: {
                'Authorization': user ? user?.auth_token : "",
            }
        })
        if (status > 199 && status < 400) {
            return data
        } if (status === 401) {
            localstorage.set(USER_ACCOUNT_KEY, null)
        }
        logger.error({ method: "GET", url, status })
        throw new Error(`error with request: ${url}`)
    } catch (err: any) {
        const axErr = (err as AxiosError)
        if (axErr.response) {
            if (axErr.response.status === 401) {
                localstorage.set(USER_ACCOUNT_KEY, null)
                // Hack currently
                window.location.reload()
            }
        }
        throw err
    }
}

export async function post<T>(uri: string, payload: any, headers: any): Promise<T> {
    const user = localstorage.get<LoggedInUser>(USER_ACCOUNT_KEY)
    let url = uri
    try {
        const { status, data } = await axios.post<T>(url, payload, {
            headers: {
                'Authorization': user ? user?.auth_token : "",
            }
        })
        logger.debug("response", status)
        return data
    } catch (err) {
        logger.error("error occurred with request", err)
        const axErr = (err as AxiosError)
        if (axErr.response) {
            if (axErr.response.status === 401) {
                logger.error("unsetting user")
                localstorage.set(USER_ACCOUNT_KEY, null)
                window.location.reload()
            }
        }
        throw err
    }
}

const httprequest = { get, post }
export default httprequest