import { ReactElement } from "react";

export default function Card(props: { children?: ReactElement | ReactElement[] }) {
    return (
        <div className="grid grid-row-1 mx-auto justify-items-center">
            <div className="inline-block align-bottom bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-4 sm:align-middle sm:max-w-lg sm:w-full"
                role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                {props.children}
            </div>
        </div>
    )
}
