import { useState } from "react"
import logger from "./lib/logger"

interface QuestionInputProp {
  validator?(v: any): boolean
  onchange(v: any): void
  type: string
  pretext?: any
  posttext?: string
  placeholder: string
  hint?: string
  defaultValue?: string
}

function QuestionInput(props: QuestionInputProp) {
  const [valid, setValid] = useState<boolean>(true)

  const valueUpdated = (v: any) => {
    let nowValid = valid
    if (props.validator) {
      nowValid = props.validator(v)
      setValid(nowValid)
    }

    if (nowValid) {
      logger.debug("Updating: ", v)
      if (props.type === "number") {
        return props.onchange(parseInt(v, 10))
      }
      props.onchange(v)
    }
  }

  return (
    <div className="bg-gray-50 px-4 py-3 sm:px-6 flex flex-col">
      <div className="sm:flex ">
        <div className="flex flex-wrap items-stretch w-full relative h-15 bg-white rounded mb-3">
          {props.pretext && (
            <div className="flex -mr-px justify-center w-15 p-1">
              <span
                className="flex items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-l text-gray-600"
              >
                {props.pretext}
              </span>
            </div>
          )}
          <input
            type={props.type}
            placeholder={props.placeholder}
            onChange={(evt) => valueUpdated(evt.target.value)}
            className={`w-full leading-normal rounded border-0 h-10  ${valid ? "border-grey-light" : "border-2 border-red-300"} px-3 self-center relative  font-roboto text-l outline-none`}
            defaultValue={props.defaultValue}
          />
          {props.posttext && (
            <div className="flex -mr-px justify-center w-15 p-1 ">
              <span
                className="flex items-center leading-normal bg-white px-3 border-0 rounded rounded-r-none text-l text-gray-600"
              >
                {props.posttext}
              </span>
            </div>
          )}
        </div>
      </div>
      {!valid && <p className="text-red-300">{props.hint}</p>}
    </div>
  )
}

export default QuestionInput