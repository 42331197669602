import { useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../context/UserContext";

function LinkText({ message }: { message: string }) {
  return (
    <p className="px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-sm md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
      {message}
    </p>
  );
}

interface NavbarProps {
  login(): void;
  logout(): void;
}

function Navbar(props: NavbarProps) {
  const { isLoggedIn } = useUser();
  const [isMenuOpen, setIsMenuOpen] = useState<Boolean>(false);

  const user = useUser();

  const onLoginClick = () => {
    user.setUser(null);
    props.login();
  };
  const onLogout = () => {
    if (user.isLoggedIn()) {
      user.setUser(null);
    }
    props.logout();
  };

  return (
    <div className="">
      <div className="antialiased bg-gray-100 ">
        <div className="w-full text-gray-700 bg-white  ">
          <div className="flex flex-col max-w-screen-xl px-4 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
            <div className="flex flex-row items-center justify-between p-4">
              <Link to="/">
                <p className="text-md font-semibold tracking-widest text-gray-900 uppercase rounded-sm us:outline-none focus:shadow-outline">
                  realshift
                </p>
              </Link>
              <button
                className="rounded-sm md:hidden focus:outline-none focus:shadow-outline"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <svg
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  className="w-6 h-6"
                >
                  {isMenuOpen ? (
                    <path
                      x-show="open"
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  ) : (
                    <path
                      x-show="!open"
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    ></path>
                  )}
                </svg>
              </button>
            </div>
            <nav
              className={
                (isMenuOpen ? "flex" : "hidden") +
                " flex-col flex-grow pb-4 md:pb-0 md:flex md:justify-end md:flex-row align-center"
              }
            >
              <Link to="/contact">
                <LinkText message="Contact" />
              </Link>

              {isLoggedIn() ? (
                <>
                  <Link to="/settings">
                    <LinkText message="Settings" />
                  </Link>
                  {/* <Link to="/dashboard">
                    <LinkText message="Dashboard" />
                  </Link> */}
                  <Link to="/search">
                    <LinkText message="Search" />
                  </Link>
                  <>
                    <button onClick={onLogout}>
                      <p className="min-width-full text-red-300 bg-gray-200 items-center px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-sm k-mode:focus:text-white md:w-auto md:inline md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
                        Logout
                      </p>
                    </button>
                  </>
                </>
              ) : (
                <button onClick={onLoginClick}>
                  <p className="text-gray-900 text-center bg-gray-200 items-center px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-sm  md:w-auto md:inline md:mt-0 md:ml-4 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">
                    {isLoggedIn() ? "Logout" : "Login / Signup"}
                  </p>
                </button>
              )}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
