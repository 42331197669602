
export interface BinarySelectOption {
    value: any
    label: string
}

export const BinaryOption = {
    Undefined: 0,
    Yes: 1,
    No: 2,
}

export const SupermarketOptions: BinarySelectOption[] = [
    { label: "Yes", value: BinaryOption.Yes },
    { label: "No", value: BinaryOption.No }
]
export interface LocationInfo {
    name: string
    loc_type: string
}


export const defaultLocationSuggestions: LocationInfo[] = [
    { name: "Bromley", loc_type: "district" },
    { name: "Croydon", loc_type: "district" },
    { name: "Kingston upon Thames", loc_type: "district" },
    { name: "Hackney", loc_type: "district" },
    { name: "Harrow", loc_type: "district" },
    { name: "Kensington and Chelsea", loc_type: "district" },
]

export enum LocationType {
    Area = "area",
    District = "district"
}

export enum Platform {
    Zoopla = "zoopla",
    Rightmove = "rightmove"
}

export const PlatformColor = new Map<string, string>(
    [
        [Platform.Zoopla, "#8046F1"],
        [Platform.Rightmove, "#00DEB6"],
    ]
) 
