import { useEffect, useState } from 'react'
import logger from './lib/logger'

interface TwoHeadsliderProps {
    min: number
    max: number
    step: number
    leftTitle: any
    rightTitle: any

    defaultMin?: number
    defaultMax?: number

    updateMinValue(minValue: number): void
    updateMaxValue(minValue: number): void
}


export default function TwoHeadslider(props: TwoHeadsliderProps) {
    const calcMinthumb = (min: number, max: number, minval: number) => ((minval - min) / (max - min)) * 100
    const calcMaxthumb = (min: number, max: number, maxval: number) => 100 - (((maxval - min) / (max - min)) * 100)

    const currentMin = Math.min(props.min + props.step, props.max - props.step)
    const [minval, setMinVal] = useState<number>(currentMin)

    const currentMax = Math.max(props.max - props.step, minval + props.step)
    const [maxval, setMaxVal] = useState<number>(currentMax)

    const [minthumb, setMinthumb] = useState<number>(calcMinthumb(props.min, props.max, minval))
    const [maxthumb, setMaxthumb] = useState<number>(calcMaxthumb(props.min, props.max, maxval))

    const [minInValue, setMinInValue] = useState<number>(minval)
    const [maxInValue, setMaxInValue] = useState<number>(maxval)
    const [validMinInput, setValidMinInput] = useState<boolean>(true)
    const [validMaxInput, setValidMaxInput] = useState<boolean>(true)


    useEffect(() => {
        if (props.defaultMin) {
            setMinVal(props.defaultMin!)
            setMinthumb(calcMinthumb(props.min, props.max, props.defaultMin))
            setMinInValue(props.defaultMin)
        }
        if (props.defaultMax) {
            setMaxVal(props.defaultMax!)
            setMaxthumb(calcMaxthumb(props.min, props.max, props.defaultMax))
            setMaxInValue(props.defaultMax)
        }
    }, [props.defaultMin, props.defaultMax, props.min, props.max])


    const updateMinValue = (v: number) => {
        setMinVal(v) // local
        if (props.updateMinValue) {
            props.updateMinValue(v)
        }
    }

    const updateMaxValue = (v: number) => {
        setMaxVal(v) // local
        if (props.updateMaxValue) {
            props.updateMaxValue(v)
        }
    }

    const mintrigger = (e: any) => {
        const eventValue = parseInt(e.target.value)
        if (!isNaN(eventValue)) {
            const minp = Math.min(eventValue, maxval - props.step)
            setMinthumb(calcMinthumb(props.min, props.max, minp))
            updateMinValue(minp)
            setMinInValue(minp)
        }
    }

    const maxtrigger = (e: any) => {
        const eventValue = parseInt(e.target.value)
        if (!isNaN(eventValue)) {
            const maxp = Math.max(eventValue, minval + props.step)
            setMaxthumb(calcMaxthumb(props.min, props.max, maxp))
            updateMaxValue(maxp)
            setMaxInValue(maxp)
        }
    }

    const minInputTrigger = (e: any) => {
        const eventValue = parseInt(e.target.value)
        logger.debug("mintrigger ", eventValue)
        if (!isNaN(eventValue)) {
            const numval = eventValue
            if (numval >= props.min && numval < maxval) {
                updateMinValue(numval)
                setMinthumb(calcMinthumb(props.min, props.max, numval))
            } else {
                setValidMinInput(false)
            }
            setMinInValue(eventValue)
        } else {
            setMinInValue(0)
        }
    }

    const maxInputTrigger = (e: any) => {
        const eventValue = parseInt(e.target.value)
        logger.debug("max input trigger", eventValue)
        if (!isNaN(eventValue)) {
            const numval = eventValue
            if (numval > minval && numval <= props.max) {
                updateMaxValue(numval)
                setMaxthumb(calcMaxthumb(props.min, props.max, numval))
            }
            setMaxInValue(eventValue)
        } else {
            setValidMaxInput(false)
            setMaxInValue(0)
        }
    }

    const minInputOnBlur = () => {
        if (validMinInput) return
        setMinInValue(minval)
    }

    const maxInputOnBlur = () => {
        if (validMaxInput) return
        setMaxInValue(maxval)
    }

    return (
        <div className="px-4 py-5 sm:px-6">
            <div className="relative max-w-xl w-full">
                <div>
                    <input type="range"
                        step={props.step}
                        min={props.min}
                        max={props.max}
                        onInput={mintrigger}
                        value={minval}
                        className="absolute top-0 left-0 pointer-events-none appearance-none z-20 h-2 w-full opacity-0 cursor-pointer inslide" />
                    <input type="range"
                        step={props.step}
                        min={props.min}
                        max={props.max}
                        onInput={maxtrigger}
                        value={maxval}
                        className="absolute top-0 left-0 pointer-events-none appearance-none z-20 h-2 w-full opacity-0 cursor-pointer inslide" />
                    <div className="relative z-10 h-2">
                        <div className="absolute z-10 left-0 right-0 bottom-0 top-0 rounded-md bg-gray-200"></div>
                        <div
                            className="absolute z-20 top-0 bottom-0 rounded-md bg-green-300"
                            style={{ right: maxthumb + '%', left: minthumb + '%' }}></div>
                        <div
                            className="absolute z-30 w-6 h-6 top-0 left-0 bg-green-300 rounded-full -mt-2 -ml-1"
                            style={{ left: minthumb + '%' }}>
                        </div>
                        <div
                            className="absolute z-30 w-6 h-6 top-0 right-0 bg-green-300 rounded-full -mt-2 -mr-3"
                            style={{ right: maxthumb + '%' }}></div>
                    </div>
                </div>
                <div className="flex justify-between items-center pt-5 pb-2">
                    <div>
                        <div className="flex flex-row">
                            <span className="flex items-center rounded rounded-r-none px-3 font-bold text-grey-darker bg-white">{props.leftTitle}</span>
                            <input type="text"
                                maxLength={5}
                                onInput={minInputTrigger}
                                value={minInValue}
                                onBlur={minInputOnBlur}
                                className="bg-gray-100 px-3 py-2 border-0 rounded w-24 text-center outline-none" />
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className="flex flex-row">
                                <span className="flex items-center rounded rounded-r-none px-3 font-bold text-grey-darker bg-white">{props.rightTitle}</span>
                                <input type="text"
                                    maxLength={5}
                                    onInput={maxInputTrigger}
                                    value={maxInValue}
                                    onBlur={maxInputOnBlur}
                                    className="bg-gray-100 px-3 py-2 border-0 rounded w-24 text-center outline-none" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}