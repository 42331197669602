const localstorage = {
    get<T>(key: string): T | null {
        const value = localStorage.getItem(key)
        if (!value) return null
        return JSON.parse(value) as T
    },
    set<T>(key: string, value: T | null) {
        if (!value) {
            localStorage.setItem(key, "")
        }
        const serialisedValue = JSON.stringify(value)
        localStorage.setItem(key, serialisedValue)
    }
}
export default localstorage