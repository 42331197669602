import axios  from "axios"
import { useEffect, useState } from "react"
import httprequest from "../../components/lib/httprequest"
import logger from "../../components/lib/logger"
import { REAL_SHIFT_HOST } from "../../constants"
import { ErrLogout } from "./errorcodes"


export interface SearchCriteria {
    _id: string
    account_id?: string,
    travel_time: number
    price_min: number
    price_max: number
    beds_min: number
    beds_max: number
    work_postcode: string
    included_areas?: string[]
    excluded_areas?: string[]
    included_districts?: string[]
    excluded_districts?: string[]
    near_supermarkets: number
    created_at?: string
    updated_at?: string
}

interface CriteriaResponse {
    criterias: SearchCriteria[]
}

export async function getCriterias(authToken: string): Promise<CriteriaResponse | null> {
    const uri = `${REAL_SHIFT_HOST}/criteria?Authorization=${authToken}`
    return httprequest.get<CriteriaResponse>(uri)
}

export async function getCriteria(authToken: string, criteriaID: string): Promise<SearchCriteria> {
    const uri = REAL_SHIFT_HOST + `/criteria?Authorization=${authToken}&criteria_id=${criteriaID}`
    return httprequest.get<SearchCriteria>(uri)
}

export default function useGetCriterias(authToken: string) {
    const [criterias, setCriterias] = useState<SearchCriteria[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [logout, setLogout] = useState<boolean>(false)

    useEffect(() => {
        setLoading(true)
        getCriterias(authToken)
            .then((result) => {
                if (result) setCriterias(result.criterias)
            })

            .catch(err => {
                logger.debug("what error", err.message)
                setLogout(err === ErrLogout)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [authToken])

    return {
        loading,
        logout,
        criterias: criterias || [],
        setCriterias,
    }
}

export async function deleteCriteria(authToken: string, criteriaID: string): Promise<boolean> {
    try {
        await axios.delete<{}>(`${REAL_SHIFT_HOST}/delete_criteria?criteria_id=${criteriaID}&Authorization=${authToken}`)
    } catch (e) {
        return false
    }
    return true
}