import { ReactElement, useEffect, useRef, useState } from "react"
import logger from "./lib/logger"

interface DropdownProps {
    children: ReactElement[] | ReactElement
    elem: ReactElement[] | ReactElement | undefined
    ddWidthOverride?: number
    ddAlign?: string
    buttonOverride?: boolean
    isOpen(open: boolean): void
    outsideClicked?(): void
    defaultIsOpen: boolean
}

export default function Dropdown(props: DropdownProps) {
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(props.defaultIsOpen)
    const ddWidthOverride = props.ddWidthOverride ? `w-${props.ddWidthOverride}` : ""
    // const ddAlign = props.ddAlign || "left"

    const ref = useRef(null)

    useEffect(() => {
        if (props.defaultIsOpen !== undefined || props.defaultIsOpen !== null)
            setDropdownOpen(props.defaultIsOpen)
    }, [props.defaultIsOpen])

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !(ref.current as any).contains(event.target)) {
                if (dropdownOpen) {
                    props.outsideClicked && props.outsideClicked()
                    setDropdownOpen(false)
                    props.isOpen(false)
                }
            }
        }
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [props.outsideClicked])

    return (
        <div className="text-center" ref={ref}>
            <div
                className="relative inline-block text-left"
            >
                {(props.buttonOverride && props.buttonOverride!) ?
                    props.elem :
                    <button
                        onClick={() => {
                            logger.debug("dropdown open", !dropdownOpen)
                            setDropdownOpen(!dropdownOpen)
                            props.isOpen(!dropdownOpen)
                        }}
                        className={`bg-primary flex flex-row items-center justify-center rounded text-base font-semibold text-black`}
                    >
                        {props.elem}
                    </button>
                }
                <div
                    className={`${dropdownOpen ? "top-full opacity-100 visible" : "top-[110%] invisible opacity-0"} border-light shadow-card absolute ${props.ddAlign}-0 z-40 mt-2 px-2 ${ddWidthOverride} rounded border-[.5px] bg-white transition-all`}
                >
                    {props.children}
                </div>
            </div >
        </div>
    )
}


