import { useParams } from "react-router-dom";
import FormApp from "../components/FormApp";

export default function Criteria() {
    const { criteria_id } = useParams()
    // console.log("criteria id", criteria_id)
    return (
        <FormApp criteria_id={criteria_id} />
    );
}

