import { useEffect, useState, useRef } from "react";
import { isValid } from "postcode";
import TwoHeadsliderSide from "../components/TwoThumbSliderSide";
import SearchPostcode from "../components/SearchPostcode";
import {
  TbBed,
  TbCurrencyPound,
  TbChevronLeft,
  TbChevronRight,
  TbClock,
} from "react-icons/tb";
import { REAL_SHIFT_HOST } from "../constants";
import { MatchedListing } from "../data/rest/useGetListings";
import {
  defaultLocationSuggestions,
  LocationType,
  PlatformColor,
  BinaryOption,
} from "../data/common";
import { useUser } from "../context/UserContext";
import moment from "moment";
import LocationTagSearch from "../components/LocationTagSearch";
import LoginRedirect from "../components/LoginRedirect";
import httprequest from "../components/lib/httprequest";
import ViewportList from "react-viewport-list";
import Toggle from "../components/Toggle";
import logger from "../components/lib/logger";
import { useLocation } from "react-router-dom";
import { SearchCriteria } from "../data/rest/useGetCriteria";

const defaultMinPrice = 100;
const defaultMaxPrice = 1000;

const defaultMinBeds = 0;
const defaultMaxBeds = 10;

function searchCriteria(
  criteria: SearchCriteria,
  accountID?: string,
  criteriaID?: string,
  authToken?: string
): Promise<MatchedListing[]> {
  return new Promise<MatchedListing[]>((resolve, reject) => {
    let endpoint = `${REAL_SHIFT_HOST}/criteria_v2_listings?account_id=${accountID}`;
    // add auth
    if (authToken) {
      endpoint += `&Authorization=${authToken!}`;
    }
    // add criteria id
    if (criteriaID) {
      endpoint += `&criteria_id=${criteriaID!}`;
    }

    httprequest
      .post<MatchedListing[]>(endpoint, criteria, {
        headers: { "Content-Type": "application/json" },
      })
      .then((data) => {
        resolve(data);
      })
      .catch(function (res) {
        reject(res);
      });
  });
}

interface MatchedListingSlotProps {
  listing: MatchedListing;
}

function hasImage(m: MatchedListing): boolean {
  return m.listing.images && m.listing.images.length > 0;
}

function imagesLen(m: MatchedListing): number {
  if (hasImage(m)) {
    return m.listing.images && m.listing.images.length;
  }
  return 0;
}

function ImageTouchSlider({ listing }: { listing: MatchedListing }) {
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const nImages = imagesLen(listing);

  const cycle = (n: number) => {
    if (selectedImageIndex + n < 0) {
      setSelectedImageIndex(nImages - 1);
    } else if (selectedImageIndex + n === nImages) {
      setSelectedImageIndex(0);
    } else {
      setSelectedImageIndex(selectedImageIndex + n);
    }
  };

  return (
    <>
      {hasImage(listing) ? (
        <div className="relative w-full">
          <img
            src={listing.listing.images[selectedImageIndex]}
            alt={""}
            className={`h-[320px] w-full object-cover hover:opacity-90`}
          />
          <button
            onClick={() => {
              cycle(-1);
            }}
          >
            <TbChevronLeft
              size={30}
              color="#3E3E42"
              style={{ transform: "translate(0,-50%)" }}
              className="absolute left-2 top-1/2 bg-gray-200 bg-opacity-30 px-1 rounded hover:bg-opacity-70"
            />
          </button>
          <button
            onClick={() => {
              cycle(1);
            }}
          >
            <TbChevronRight
              size={30}
              className="absolute right-2 top-1/2 px-1 rounded bg-opacity-30 bg-gray-200 hover:bg-opacity-70"
              style={{ transform: "translate(0,-50%)" }}
              color="#3E3E42"
            />
          </button>
          <div
            className="absolute bottom-8 left-1/2 flex flex-row space-x-1"
            style={{ transform: "translate(-50%,0)" }}
          >
            {Array.from(Array(nImages).keys()).map((i, ix) => (
              <button
                key={i}
                onClick={() => setSelectedImageIndex(i)}
                className={`w-1.5 h-1.5 bg-white shadow border rounded-full opacity-${
                  selectedImageIndex === i ? 100 : 60
                }`}
              ></button>
            ))}
          </div>
        </div>
      ) : (
        <div className="h-[300px] w-full bg-gray-200 rounded object-cover hover:opacity-90 items-center flex justify-center">
          <p className="font-bold text-gray-500">No Image</p>
        </div>
      )}
    </>
  );
}

function capitaliseFirst(s: string) {
  if (s.length > 0) return s[0].toUpperCase() + s.slice(1);
  return s;
}

function PlatformMenu({ listing }: { listing: MatchedListing }) {
  return (
    <a
      target={"_blank"}
      rel="noreferrer"
      className="flex flex-row items-center space-x-1 border border-1 border-gray-500 px-2 rounded-xl"
      href={listing.listing.url}
    >
      <svg height="10" width="10">
        <circle
          cx="5"
          cy="5"
          r="5"
          fill={PlatformColor.get(listing.listing.platform) || "#cccccc"}
        />
      </svg>
      <p className="font-semibold text-sm text-gray-500">
        {capitaliseFirst(listing.listing.platform)}
      </p>
    </a>
  );
}

function MatchedListingSlot(p: MatchedListingSlotProps) {
  return (
    <div className="w-full flex flex-col space-y-3 md:flex-row md:space-x-4 p-2">
      <div className="flex w-full md:w-1/2 items-center">
        <ImageTouchSlider listing={p.listing} />
      </div>
      <div className="flex flex-col flex-1 space-y-2">
        <div className="flex flex-row items-center">
          <PlatformMenu listing={p.listing} />
          {/* add badges */}
        </div>
        {/* main header */}
        <div className="flex flex-row items-center justify-between">
          <p className="flex flex-row items-center space-x-1 font-semibold text-lg text-gray-500">
            <span>{p.listing.listing.bedrooms}</span>
            <span>
              {" "}
              <TbBed size={25} />
            </span>
          </p>
          <p className="font-bold text-xl text-gray-800">
            £{p.listing.listing.price_per_month} pcm
          </p>
        </div>
        {/* location */}
        <div className="flex flex-row">
          <p className="text-gray-700 font-bold ">
            {p.listing.listing.address}
          </p>
        </div>
        {/* tag badges */}
        {p.listing.account_listing.average_travel_time && (
          <div className="flex flex-row items-center space-x-1">
            <TbClock color="gray" size={25} />
            <p className="font-semibold text-base text-gray-500">
              <span>
                {`${p.listing.account_listing.average_travel_time} mins average commute`}
              </span>
            </p>
            <p></p>
          </div>
        )}
        {p.listing.listing.district && p.listing.listing.area && (
          <div className="flex flex-col space-y-0.5">
            <p className="font-bold text-sm py-0.5 rounded-xl text-gray-500 ">
              Area: {p.listing.listing.area}
            </p>
            <p className="font-bold text-sm py-0.5 rounded-xl text-gray-500 ">
              Borough: {p.listing.listing.district}
            </p>
            {/* <p>{p.listing.account_listing.average_travel_time} mins</p> */}
          </div>
        )}
        <div className="flex flex-row items-center justify-between">
          {/* badges */}
          <div className="flex flex-row flex-wrap space-x-2">
            {[].map((item) => (
              <span
                key={item}
                className="text-xs font-bold text-gray-500 bg-gray-200 px-2 py-1 rounded"
              >
                {item}
              </span>
            ))}
          </div>
          <p className="text-gray-500 text-sm font-semibold">
            {moment(p.listing.listing.date_listed).fromNow()}
          </p>
        </div>
      </div>
    </div>
  );
}

interface DistrictHeaderProps {
  listings: MatchedListing[];
  defaultValue: string;
  onClick(value: string): void;
}
function DistrictHeaders(props: DistrictHeaderProps) {
  const [selectedItem, setSelectedItem] = useState<string>(props.defaultValue);

  let item: { [key: string]: number } = {};
  const result = Object.entries(
    props.listings.reduce((acc, cur) => {
      const district = cur.listing.district;
      acc[district] = (acc[district] || 0) + 1;
      return acc;
    }, item)
  )
    .sort((a, b) => b[1] - a[1])
    .map(([district, count]) => (
      <button
        key={district}
        onClick={() => {
          const selected = district === selectedItem ? "" : district;
          setSelectedItem(selected);
          props.onClick(selected);
        }}
        className={`flex mx-3 my-1 py-1 px-2 flex-row space-x-2 ${
          selectedItem === district ? "bg-green-400" : "bg-green-200"
        } rounded-lg`}
      >
        <span className="text-sm">{district}</span>
        <span className="text-sm font-semibold">{count}</span>
      </button>
    ));

  useEffect(() => {
    setSelectedItem(props.defaultValue);
  }, [props.defaultValue]);

  return result.length ? (
    <div className="flex flex-row flex-wrap">{result}</div>
  ) : (
    <></>
  );
}

export default function Search() {
  const { getUser } = useUser();

  const ref = useRef(null);
  const searchRef = useRef<HTMLDivElement | null>(null);
  const virtualListRef = useRef<HTMLDivElement | null>(null);

  const { state } = useLocation();

  const [firstSearched, setFirstSearched] = useState<boolean>(true);

  const [postcode, setPostcode] = useState<string>("");
  const [commuteTime, setCommuteTime] = useState<number>(-1);

  const [minPrice, setMinPrice] = useState<number>(defaultMinPrice);
  const [maxPrice, setMaxPrice] = useState<number>(defaultMaxPrice);

  const [minBeds, setMinBeds] = useState<number>(defaultMinBeds);
  const [maxBeds, setMaxBeds] = useState<number>(defaultMaxBeds);

  const [includedAreas, setIncludedAreas] = useState<string[]>([]);
  const [excludedAreas, setExcludedAreas] = useState<string[]>([]);

  const [includedDistricts, setIncludedDistricts] = useState<string[]>([]);
  const [excludedDistricts, setExcludedDistricts] = useState<string[]>([]);

  const [nearSupermarkets, setNearSupermarkets] = useState<number>(
    BinaryOption.Yes
  );

  const [isSearching, setIsSearching] = useState<boolean>(false);

  const [selectedDistrict, setSelectedDistrict] = useState<string>("");
  const [matchedListings, setMatchedListings] = useState<MatchedListing[]>([]);
  const [filteredListings, setFilteredListings] = useState<MatchedListing[]>(
    []
  );

  const processListings = (district: string = selectedDistrict) => {
    if (!district) {
      return matchedListings;
    }
    return matchedListings.filter((elem) => {
      return elem.listing.district === district;
    });
  };

  const onSearch = async (criteria: SearchCriteria | null = null) => {
    if (firstSearched) setFirstSearched(!firstSearched);
    setSelectedDistrict("");
    setIsSearching(true);
    let searchPayload: SearchCriteria;
    if (!criteria) {
      searchPayload = {
        _id: "",
        work_postcode: postcode,
        included_areas: includedAreas,
        excluded_areas: excludedAreas,
        included_districts: includedDistricts,
        excluded_districts: excludedDistricts,
        price_max: maxPrice,
        price_min: minPrice,
        beds_min: minBeds,
        beds_max: maxBeds,
        near_supermarkets: nearSupermarkets,
        travel_time: commuteTime,
      };
    } else {
      searchPayload = criteria!;
      logger.debug("received search criteria", criteria);
    }

    searchRef.current?.scroll({ top: 0, behavior: "smooth" });

    const user = getUser();
    searchCriteria(searchPayload, user?.account_id, undefined, user?.auth_token)
      .then((data) => {
        const result = data ? data : [];
        setMatchedListings(result);
        setFilteredListings(result);
      })
      .catch((err) => console.error(err))
      .finally(() => setIsSearching(false));
    logger.debug("searching for criteria", searchPayload);
  };

  useEffect(() => {
    if (state) {
      const criteria: SearchCriteria = state as SearchCriteria;

      setPostcode(criteria.work_postcode);
      setNearSupermarkets(criteria.near_supermarkets);
      setCommuteTime(criteria.travel_time);

      setMinPrice(criteria.price_min);
      setMaxPrice(criteria.price_max);

      setMinBeds(criteria.beds_min);
      setMaxBeds(criteria.beds_max);

      if (criteria.included_areas) {
        setIncludedAreas(criteria.included_areas);
      }
      if (criteria.excluded_areas) {
        setExcludedAreas(criteria.excluded_areas);
      }
      if (criteria.included_districts) {
        setIncludedDistricts(criteria.included_districts);
      }
      if (criteria.excluded_districts) {
        setExcludedDistricts(criteria.excluded_districts);
      }

      onSearch(criteria);
    }
  }, [state]); // eslint-disable-line

  return (
    <LoginRedirect>
      <div className="flex flex-col sm:flex-row rounded justify-center sm:h-[calc(100vh-130px)]">
        <div
          ref={ref}
          className="flex flex-col xs:w-full sm:w-5/12 p-4 space-y-5 shadow-sm"
        >
          <SearchPostcode
            onChange={(v) => {
              setPostcode(v.postcode);
              setCommuteTime(v.commuteTime);
            }}
            defaultValue={{ postcode: postcode, commuteTime: commuteTime }}
            placeholder="Enter a postcode (e.g WC1A 2BB)"
            validator={isValid}
          />

          <div className="flex flex-col space-y-2">
            <p className="text-xs uppercase font-semibold">Include Areas</p>
            <LocationTagSearch
              hint="NOTE: This will exclude properties in this area"
              placeholder="Start typing.. e.g. Croydon"
              defaultValues={[
                ...includedAreas.map((area) => ({
                  name: area,
                  loc_type: "area",
                })),
                ...includedDistricts.map((area) => ({
                  name: area,
                  loc_type: "district",
                })),
              ]}
              defaultSuggestions={defaultLocationSuggestions}
              onChange={(locs) => {
                const areas: string[] = [];
                const districts: string[] = [];
                for (let i = 0; i < locs.length; i++) {
                  const locType = locs[i].loc_type;
                  if (locType === LocationType.Area) {
                    areas.push(locs[i].name);
                  } else if (locType === LocationType.District) {
                    districts.push(locs[i].name);
                  }
                }
                setIncludedAreas(areas);
                setIncludedDistricts(districts);
              }}
            />
          </div>
          <div className="flex flex-col space-y-2">
            <p className="text-xs uppercase font-semibold">Exclude Areas</p>
            <LocationTagSearch
              hint="NOTE: This will exclude properties in this area"
              placeholder="Start typing.. e.g. Croydon"
              defaultValues={[
                ...excludedAreas.map((area) => ({
                  name: area,
                  loc_type: "area",
                })),
                ...excludedDistricts.map((area) => ({
                  name: area,
                  loc_type: "district",
                })),
              ]}
              defaultSuggestions={defaultLocationSuggestions}
              onChange={(locs) => {
                const areas: string[] = [];
                const districts: string[] = [];
                for (let i = 0; i < locs.length; i++) {
                  const locType = locs[i].loc_type;
                  if (locType === LocationType.Area) {
                    areas.push(locs[i].name);
                  } else if (locType === LocationType.District) {
                    districts.push(locs[i].name);
                  }
                }
                setExcludedAreas(areas);
                setExcludedDistricts(districts);
              }}
            />
          </div>

          {/* PRICE */}
          <div className="flex flex-col space-y-2">
            <p className="text-xs uppercase font-semibold">price</p>
            <TwoHeadsliderSide
              min={100}
              max={10000}
              step={100}
              defaultMin={minPrice}
              defaultMax={maxPrice}
              leftTitle={<TbCurrencyPound size={18} />}
              rightTitle={<TbCurrencyPound size={18} />}
              updateMinValue={setMinPrice}
              updateMaxValue={setMaxPrice}
            />
          </div>
          {/* BEDS */}
          <div className="flex flex-col space-y-2">
            <p className="text-xs uppercase font-semibold">
              Bedrooms: <span className="text-gray-500">0 means studio</span>
            </p>
            <TwoHeadsliderSide
              min={0}
              max={10}
              step={1}
              defaultMin={minBeds}
              defaultMax={maxBeds}
              leftTitle={<TbBed size={20} />}
              rightTitle={<TbBed size={20} />}
              updateMinValue={setMinBeds}
              updateMaxValue={setMaxBeds}
            />
          </div>

          {/* NEAR STUFF */}
          <div className="flex flex-col space-y-2">
            <p className="text-xs uppercase font-semibold">Amenities</p>
            <div className="flex flex-row items-center justify-between">
              <p className="font-semibold text-xs">Include Supermarkets</p>
              <Toggle
                defaultValue={
                  nearSupermarkets === BinaryOption.Yes ? true : false
                }
                onChange={(change) => {
                  setNearSupermarkets(
                    change ? BinaryOption.Yes : BinaryOption.No
                  );
                }}
              />
            </div>
          </div>
          <div className="flex flex-row items-center justify-end">
            <button
              className="border border-green-400 bg-green-400 text-white text-xs font-semibold uppercase px-3 py-2 rounded"
              onClick={() => onSearch()}
            >
              Search
            </button>
          </div>
        </div>
        {/* list here */}
        <div
          ref={searchRef}
          className="p-4 space-y-5 w-full sm:overflow-scroll scrollbar-hide bg-gray-50"
        >
          {firstSearched && (
            <div>
              <p className="text-lg">
                Search for the the <b>latest</b> listings in London
              </p>
            </div>
          )}
          {isSearching && (
            <div className="flex flex-row items-center space-x-2">
              <span className="visually-hidden">
                <svg
                  className="animate-spin -inline-block w-8 h-8 border-4 rounded-full"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </span>
              <p className="font-semibold text-sm">Loading....</p>
            </div>
          )}
          {!firstSearched && !isSearching && filteredListings.length === 0 && (
            <div className="flex">
              <p className="">
                There are <b>no</b> listings for that search available.
              </p>
            </div>
          )}
          {filteredListings.length > 0 && (
            <DistrictHeaders
              onClick={(value) => {
                setSelectedDistrict(value);
                setFilteredListings(processListings(value));
              }}
              defaultValue={selectedDistrict}
              listings={matchedListings}
            />
          )}
          {filteredListings.length > 0 && (
            <div className="flex flex-col space-y-2">
              <p className="text-xl">
                <span className="font-bold text-gray-800">
                  {filteredListings.length} Results
                </span>
              </p>
              <div ref={virtualListRef} className="flex flex-col space-y-4">
                <ViewportList items={filteredListings}>
                  {(matched) => (
                    <div
                      className="shadow-sm py-2 px-2 rounded"
                      key={matched.listing.url}
                    >
                      <MatchedListingSlot listing={matched} />
                    </div>
                  )}
                </ViewportList>
              </div>
            </div>
          )}
        </div>
      </div>
    </LoginRedirect>
  );
}
