import axios from "axios"
import httprequest from "../../components/lib/httprequest"
import { REAL_SHIFT_HOST } from "../../constants"
import { ErrLogout } from "./errorcodes"

interface codeResponse {
    success: boolean
}

export async function onRequestCode(email: string): Promise<codeResponse> {
    const { data, status } = await axios.get(REAL_SHIFT_HOST + "/requestcode?email=" + email)
    if (status >= 400) {
        if (status === 401) throw ErrLogout
        throw new Error("status: " + status + " body: " + data)
    }
    return data
}

interface verifyCodeResponse {
    auth_token: string
    account_id: string
    expiry: number
    role?: string
    email_alerts_enabled?: boolean
}


export async function onVerifyCode(email: string, code: string): Promise<verifyCodeResponse> {
    // const { data, status } = await axios
    //     .get<verifyCodeResponse>(REAL_SHIFT_HOST + `/verifycode?email=${email}&code=${code}`)
    // if (status >= 400) {
    //     if (status === 401) throw ErrLogout
    //     throw new Error("status: " + status + " body: " + data)
    // }
    const uri = REAL_SHIFT_HOST + `/verifycode?email=${email}&code=${code}`
    return httprequest.get<verifyCodeResponse>(uri)
}