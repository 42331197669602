function Footer() {
  return (
    <div className="bg-white shadow flex flex-row justify-between p-6">
      <p className="text-base">
        &copy; 2022 Real shift. All rights reserved
      </p>
      <div className="flex flex-row space-x-5">
        <a href="https://www.instagram.com/sentimentshark/">
        </a>
      </div>
    </div>
  );
}

export default Footer;
