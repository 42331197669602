import { useRef, useState } from "react";
import { useUser } from "../context/UserContext";
import Searchbar from "./Searchbar";
import { SearchCriteria } from "../data/rest/useGetCriteria";
import { MatchedListing } from "../data/rest/useGetListings";
import { REAL_SHIFT_HOST } from "../constants";
import httprequest from "../components/lib/httprequest";
import DistrictHeaders from "../components/DistrictHeaders";
import ViewportList from "react-viewport-list";
import MatchedListingSlot from "../components/MatchListingSlot";
import logger from "../components/lib/logger";

function searchCriteria(
  criteria: SearchCriteria,
  accountID?: string,
  criteriaID?: string,
  authToken?: string
): Promise<MatchedListing[]> {
  return new Promise<MatchedListing[]>((resolve, reject) => {
    let endpoint = `${REAL_SHIFT_HOST}/criteria_v2_listings?account_id=${accountID}`;
    // add auth
    if (authToken) {
      endpoint += `&Authorization=${authToken!}`;
    }
    // add criteria id
    if (criteriaID) {
      endpoint += `&criteria_id=${criteriaID!}`;
    }

    httprequest
      .post<MatchedListing[]>(endpoint, criteria, {
        headers: { "Content-Type": "application/json" },
      })
      .then((data) => {
        resolve(data);
      })
      .catch(function (res) {
        reject(res);
      });
  });
}

export default function SearchModal() {
  const { getUser } = useUser();

  const virtualListRef = useRef<HTMLDivElement | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [selectedDistrict, setSelectedDistrict] = useState<string>("");
  const [matchedListings, setMatchedListings] = useState<MatchedListing[]>([]);
  const [filteredListings, setFilteredListings] = useState<MatchedListing[]>(
    []
  );

  const processListings = (district: string = selectedDistrict) => {
    if (!district) {
      return matchedListings;
    }
    return matchedListings.filter((elem) => {
      return elem.listing.district === district;
    });
  };

  return (
    <div className="flex flex-col space-y-5 ">
      <div className="flex">
        <div className="z-30 relative flex justify-center min-w-full">
          <Searchbar
            isActive={(active) => {
              setIsModalOpen(active);
            }}
            onChange={(criteria) => {
              const user = getUser();
              searchCriteria(
                criteria,
                user?.account_id,
                undefined,
                user?.auth_token
              )
                .then((data) => {
                  const result = data ? data : [];
                  logger.debug("criteria", criteria);
                  logger.debug("results", result);
                  setMatchedListings(result);
                  setFilteredListings(result);
                })
                .catch((err) => console.error(err))
                .finally(() => setIsSearching(false));
            }}
          />
        </div>

        {isModalOpen && (
          <div
            className="fixed inset-0 z-[10] overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-end justify-center min-h-screen px-4 text-center md:items-center sm:block sm:p-0">
              <div
                onClick={() => setIsModalOpen(false)}
                className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-40"
                aria-hidden="true"
              >
                {" "}
              </div>
            </div>
          </div>
        )}
      </div>
      {isSearching && <>Searching now</>}
      {filteredListings.length > 0 && (
        <DistrictHeaders
          onClick={(value) => {
            setSelectedDistrict(value);
            setFilteredListings(processListings(value));
          }}
          defaultValue={selectedDistrict}
          listings={matchedListings}
        />
      )}
      {filteredListings.length > 0 && (
        <div className="flex flex-col space-y-2">
          <p className="text-xl">
            <span className="font-bold text-gray-800">
              {filteredListings.length} Results
            </span>
          </p>
          <div ref={virtualListRef} className="flex flex-col space-y-4">
            <ViewportList items={filteredListings}>
              {(matched) => (
                <div
                  className="shadow-sm py-2 px-2 rounded"
                  key={matched.listing.url}
                >
                  <MatchedListingSlot listing={matched} />
                </div>
              )}
            </ViewportList>
          </div>
        </div>
      )}
    </div>
  );
}
